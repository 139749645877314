<template>
  <a-drawer
    :visible="visible"
    :title="$t('changeHistory')"
    :width="600"
    @close="$emit('close')"
  >
    <div>
      <a-list>
        <a-list-item>
          <a-list-item-meta :description="$t('lastOption')">
            <template #title>{{ originalMessageBody }}</template>
          </a-list-item-meta>
        </a-list-item>
      </a-list>

      <a-list :dataSource="editHistory">
        <template #renderItem="item">
          <a-list-item>
            <a-list-item-meta :description="formatDateAndTime(item.edited_at)">
              <template #title>{{ item.body }}</template>
            </a-list-item-meta>
          </a-list-item>
        </template>
      </a-list>
    </div>
  </a-drawer>
</template>

<script setup>
import getCurrentLocale from "@/utils/getCurrentLocale"

const props = defineProps({
  visible: {
    type: Boolean,
    required: true
  },
  editHistory: {
    type: Array,
    required: true
  },
  originalMessageBody: {
    type: String,
    required: true
  }
})

const formatDateAndTime = (date) => {
  const currentLocale = getCurrentLocale()
  const dateObj = new Date(date)
  const now = new Date()

  const isToday = dateObj.toDateString() === now.toDateString()
  const isYesterday =
    new Date(now.setDate(now.getDate() - 1)).toDateString() === dateObj.toDateString()
  const isSomeYear = dateObj.getFullYear() === new Date().getFullYear()

  const timeFormatter = new Intl.DateTimeFormat(currentLocale, {
    hour: "numeric",
    minute: "numeric"
  })

  if (isToday) {
    return timeFormatter.format(dateObj)
  } else if (isYesterday) {
    return "Yesterday " + timeFormatter.format(dateObj)
  } else if (isSomeYear) {
    const dateFormatter = new Intl.DateTimeFormat(currentLocale, {
      day: "numeric",
      month: "long",
      hour: "numeric",
      minute: "numeric"
    })
    return dateFormatter.format(dateObj)
  } else {
    const fullDateFormatter = new Intl.DateTimeFormat(currentLocale, {
      day: "numeric",
      month: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric"
    })
    return fullDateFormatter.format(dateObj)
  }
}
</script>

<style lang="scss" scoped>
.edit-history {
  &__get-next {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
  }

  &__item {
    $item: &;
    padding: 0 8px;
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;

    &-date {
      font-size: 12px;
      color: #8c8c8c;
    }

    &-body {
      border: 1px solid #d9d9d9;
      border-radius: 8px;
      padding: 8px;
      white-space: pre-wrap;
    }

    &--original {
      #{$item}-body {
        border: 1px solid #000;
      }
    }

    &:last-child {
      border-bottom: 0;
      margin-bottom: 0;
    }
  }
}
</style>
