const dateTimeFormat = new Intl.DateTimeFormat("uk-UA", {
  day: "numeric",
  month: "numeric",
  year: "numeric",
  hour: "numeric",
  minute: "numeric",
  hourCycle: "h23"
})

const dateFormat = new Intl.DateTimeFormat("uk-UA", {
  day: "numeric",
  month: "numeric",
  year: "numeric"
})

const timeFormat = new Intl.DateTimeFormat("uk-UA", {
  timeStyle: "short",
  hourCycle: "h23"
})

const timeWithSecondsFormat = new Intl.DateTimeFormat("uk-UA", {
  timeStyle: "medium",
  hourCycle: "h23"
})

export const getFormatedDateTime = (dateTimeString) => {
  return dateTimeString ? dateTimeFormat.format(new Date(dateTimeString)).replace(",", "") : ""
}

export const getFormatedDate = (dateTimeString) => {
  return dateTimeString ? dateFormat.format(new Date(dateTimeString)) : ""
}

export const getFormatedTime = (dateTimeString, withSeconds = false) => {
  if (withSeconds) {
    return dateTimeString ? timeWithSecondsFormat.format(new Date(dateTimeString)) : ""
  }

  return dateTimeString ? timeFormat.format(new Date(dateTimeString)) : ""
}

export const getFormatedDateTimeByTimestamp = (timestampString) => {
  return timestampString
    ? dateTimeFormat.format(new Date(timestampString * 1e3)).replace(",", "")
    : ""
}

export const getFormatedDateByTimestamp = (timestampString) => {
  return timestampString ? dateFormat.format(new Date(timestampString * 1e3)) : ""
}

export const getFormatedTimeByTimestamp = (timestampString, withSeconds = false) => {
  if (withSeconds) {
    return dateTimeString ? timeWithSecondsFormat.format(new Date(timestampString * 1e3)) : ""
  }

  return timestampString ? timeFormat.format(new Date(timestampString * 1e3)) : ""
}

export const getDynamicDateFormat = (date) => {
  const dateObj = new Date(date)
  const now = new Date()

  const isToday = dateObj.toDateString() === now.toDateString()
  if (isToday) return getFormatedTime(date)

  const isSameYear = dateObj.getFullYear() === new Date().getFullYear()

  if (isSameYear) {
    const dateFormatter = new Intl.DateTimeFormat("uk-UA", {
      day: "numeric",
      month: "short",
      hour: "numeric",
      minute: "numeric"
    })
    return dateFormatter.format(dateObj).replace(",", "")
  }

  return getFormatedDateTime(date)
}
