<template>
  <a-spin
    :spinning="fetching"
    class="form"
  >
    <a-form-model
      ref="formRef"
      :model="form"
      :rules="rules"
      layout="vertical"
    >
      <div>
        <a-form-model-item
          :label="$t('public_title.ru')"
          prop="public_title.ru"
        >
          <a-input-group compact>
            <a-input
              v-model="form.public_title.ru"
              style="width: 70%"
            />

            <!-- <a-button
                :disabled="!form.public_title.ru"
                @click="onTranslate({ to: 'uk', field: 'public_title' })"
              >
                translate
              </a-button> -->
          </a-input-group>
        </a-form-model-item>

        <a-form-model-item
          :label="$t('public_title.uk')"
          prop="public_title.uk"
        >
          <a-input-group compact>
            <a-input
              v-model="form.public_title.uk"
              style="width: 70%"
            />

            <!-- <a-button
                :disabled="!form.public_title.uk"
                @click="onTranslate({ to: 'ru', field: 'public_title' })"
              >
                translate
              </a-button> -->
          </a-input-group>
        </a-form-model-item>
      </div>

      <div class="form__thin-block">
        <a-form-model-item
          :label="$t('brand')"
          prop="brand"
        >
          <InfiniteScrollSelect
            :value="form.brand"
            :defaultOptions="productBrands"
            :customRequest="fetchProductBrands"
            onFocusFetch
            @change="onBrandChange"
          />
        </a-form-model-item>

        <a-form-model-item
          :label="$t('model')"
          prop="model"
        >
          <a-input v-model="form.model" />
        </a-form-model-item>

        <a-form-model-item
          :label="`${$t('category')}:`"
          prop="category"
          required
        >
          <a-spin :spinning="!categories.length">
            <a-tree-select
              v-model="form.category.uuid"
              :treeData="categories"
              :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
              treeNodeFilterProp="title"
              showSearch
              allowClear
              @change="onCategoryChange"
            />
          </a-spin>
        </a-form-model-item>

        <a-form-model-item
          :label="$t('type')"
          prop="type"
        >
          <InfiniteScrollSelect
            ref="typeScrollRef"
            :value="form.type"
            :customRequest="fetchProductTypes"
            :defaultOptions="productTypes"
            labelKey="full_name"
            onFocusFetch
            @change="onTypeChange"
          />
        </a-form-model-item>
      </div>

      <div class="form__description">
        <a-form-model-item prop="description.ru">
          <template #label>
            {{ `${$t("description")} (RU)` }}
            <!-- <a-button
                :disabled="!form.description.ru"
                @click="onTranslate({ to: 'uk', field: 'description' })"
              >
                translate
              </a-button> -->
          </template>

          <EditorHtml
            id="productDescriptionRu"
            ref="productDescriptionRuRef"
            v-model="form.description.ru"
            replaceVideoIframe
            :headers="[false, 4]"
          />
        </a-form-model-item>

        <a-form-model-item prop="description.ru">
          <template #label>
            {{ `${$t("description")} (UA)` }}
            <!-- <a-button
                :disabled="!form.description.uk"
                @click="onTranslate({ to: 'ru', field: 'description' })"
              >
                translate
              </a-button> -->
          </template>

          <EditorHtml
            id="productDescriptionUa"
            ref="productDescriptionUaRef"
            v-model="form.description.uk"
            replaceVideoIframe
            :headers="[false, 4]"
          />
        </a-form-model-item>
      </div>
    </a-form-model>
  </a-spin>
</template>

<script setup>
import { inject, reactive, ref, computed, watch } from "vue"
import i18n from "@/i18n"
import EditorHtml from "@/components/Editor/EditorHtml.vue"
import { notification } from "ant-design-vue"

import {
  // getTranslatedText,
  fetchProductTypes,
  fetchProductBrands
} from "@/modules/Moderation/services/moderationProductsService.js"
import InfiniteScrollSelect from "@/ant-components/InfiniteScrollSelect/InfiniteScrollSelect.vue"

const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  },

  product: {
    type: Object,
    default: () => ({})
  },

  fetching: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(["onTypeChanged", "formChanged"])

const formRef = ref()
const form = reactive({
  use_public_title: true,
  public_title: {
    uk: "",
    ru: ""
  },
  brand: undefined,
  model: "",
  type: undefined,
  category: {
    uuid: ""
  },
  description: {
    uk: "",
    ru: ""
  }
})
const formIsSetted = ref(false)
const formWatcher = ref()

const productDescriptionRuRef = ref()
const productDescriptionUaRef = ref()

const categories = inject("categories")

const rules = computed(() => ({
  brand: {
    required: true,
    message: i18n.t("thisFieldMustBeFilled")
  },
  public_title: {
    ru: {
      required: true,
      message: i18n.t("thisFieldMustBeFilled")
    },
    uk: {
      required: true,
      message: i18n.t("thisFieldMustBeFilled")
    }
  },
  category: [
    {
      validator: (_, value, callback) => {
        if (value.uuid) {
          callback()
          return
        }
        callback(new Error(i18n.t("thisFieldMustBeFilled")))
      }
    }
  ]
}))

const onResetForm = (isVisible) => {
  formIsSetted.value = false

  if (isVisible) {
    setFormFields(props.product)
    return
  }

  formRef.value.resetFields()
}

const setFormFields = async (product) => {
  formWatcher.value?.()
  if (!product.code) {
    formIsSetted.value = true
    return
  } else {
    formIsSetted.value = false
  }

  const { type, brand, model, public_title, description, synthetic_meta } = product

  if (type) {
    form.type = type.oid
  }

  if (brand) {
    form.brand = brand.oid
  }

  if (synthetic_meta?.category?.uuid) {
    form.category = synthetic_meta.category
  }

  form.model = model
  form.public_title = { ...public_title }
  form.description = { ...description }

  await productDescriptionRuRef.value.setContent()
  await productDescriptionUaRef.value.setContent()

  setFormWatcher()
}

// На момент SNT-7172 немає роуту для перекладів Google translate, закоментовано до його появи
// Після появи роуту додати іконки у кнопки для перекладу
//
// const onTranslate = (payload) => {
//   if (form[field][to]) {
//     Modal.confirm({
//       title: "Поле Опис (UA) вже містить текст. Перезаписати?",
//       onOk: () => translateField(payload)
//     })
//     return
//   }

//   translateField(payload)
// }

// const translateField = async ({ to, field }) => {
//   try {
//     const from = to === "ru" ? "uk" : "ru"
//     const text = form[field][from].replace(/nbsp;/, "")
//     const res = await getTranslatedText(text, from, to)

//     if (!res.sentences) return

//     form[field][to] = res.sentences.map(({ trans }) => trans.trim()).join("")
//     formRef.value.clearValidate()

//     if (field !== "description") return

//     if (to === "ru") {
//       productDescriptionRuRef.value.setContent()
//     } else {
//       productDescriptionUaRef.value.setContent()
//     }
//   } catch (e) {
//     console.log(e)
//   }
// }

const getLocalizedFormValues = async () => {
  return new Promise(async (res, rej) => {
    formRef.value.validate((isValid, errors) => {
      if (!isValid) {
        const emptyFields = Object.keys(errors)
          .map((field) => i18n.t(field))
          .join(", ")

        notification.error({
          message: i18n.t("generalProductFormFilledWithErrors"),
          description: `${i18n.t("fillFields")}: ${emptyFields}`
        })
        rej(null)
      }

      const { public_title, description, ...values } = form

      const formRu = {
        ...values,
        public_title: public_title.ru,
        description: description.ru
      }
      const formUk = { public_title: public_title.uk, description: description.uk }

      res([formRu, formUk])
    })
  })
}

const onCategoryChange = (uuid, name) => {
  if (!uuid) {
    form.category = {}
    return
  }

  const categoryName = Array.isArray(name) ? name.join("") : name

  form.category = {
    uuid,
    name: {
      uk: categoryName,
      ru: categoryName,
      en: categoryName
    }
  }
}

// TYPES
const typeScrollRef = ref()

const productTypes = computed(() =>
  props.product?.type
    ? [{ full_name: props.product.type.full_name, oid: props.product.type.oid }]
    : []
)

const onTypeChange = (value) => {
  form.type = value
  emit(
    "onTypeChanged",
    typeScrollRef.value.selectOptions.find(({ oid }) => oid === value)
  )
}

// BRAND
const productBrands = computed(() => (props.product?.brand ? [props.product?.brand] : []))

const onBrandChange = (value) => {
  form.brand = value
}

const setFormWatcher = () => {
  formWatcher.value = watch(() => form, onFormChanged, { deep: true })
}

const resetFormWatcher = () => {
  formWatcher.value?.()
  formWatcher.value = undefined
}

const onFormChanged = () => {
  resetFormWatcher()
  emit("formChanged")
}

watch(() => props.visible, onResetForm)
watch(
  () => props.product.code,
  () => onResetForm(props.visible)
)

defineExpose({
  getLocalizedFormValues,
  onResetForm,
  setFormWatcher
})
</script>

<style lang="scss" scoped>
.form {
  position: relative;
  width: 100%;
  padding-bottom: 50px;

  &__thin-block {
    width: 50%;

    display: flex;
    flex-flow: row wrap;
    gap: 24px;

    & > * {
      width: calc(50% - 12px);
    }
  }

  &__description {
    display: flex;
    flex-flow: row nowrap;
    gap: 24px;

    & > * {
      width: calc(50% - 12px);
    }
  }
}
</style>
