import { API_MODERATION } from "utils/HttpUtils"
import apiUrls from "@/modules/ProductCatalog/store/apiUrls"
import buildMagicSearch from "@/modules/ProductCatalog/store/utils/buildMagicSearch"

const state = () => ({
  PC_allAtlsOfType: false
})

const getters = {
  PC_allAtlsOfType: (state) => state.PC_allAtlsOfType
}

const mutations = {
  PC_allAtlsOfType(state, value) {
    state.PC_allAtlsOfType = value
  }
}

const actions = {
  async PC_fetchAllAtlsOfType({ commit, getters }, type) {
    let searchData = buildMagicSearch({
      resource: "ATL",
      field: "product_type",
      value: type.code,
      sort: ["order"],
      limit: 200,
      offset: 0
    })

    return API_MODERATION.post(`${apiUrls.searchMagic}`, searchData)
      .then((r) => r.data.message.data)
      .then((atls) => {
        commit("PC_allAtlsOfType", atls)
        return atls
      })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
