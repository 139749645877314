<template>
  <div>
    <PageHeader :title="$t('locationsSettings')">
      <template #filters>
        <a-select
          :placeholder="$t('region')"
          v-model="filteredInfo.state"
          :options="stateLocationOptions"
          optionFilterProp="title"
          showSearch
          allowClear
          @change="onStateChange()"
        />

        <a-select
          :placeholder="$t('district')"
          v-model="filteredInfo.district"
          :options="districtLocationsOptions"
          optionFilterProp="title"
          showSearch
          allowClear
          @change="handleTableFilterChange()"
        />

        <a-input-search
          :placeholder="$t('settlement')"
          v-model="filteredInfo.locality"
          allowClear
          @search="handleTableFilterChange()"
        />

        <a-select
          :placeholder="$t('settlementType')"
          v-model="filteredInfo.type"
          :options="localityTypesOptions"
          allowClear
          @change="handleTableFilterChange()"
        />

        <a-select
          :placeholder="$t('status')"
          v-model="filteredInfo.is_active"
          :options="isActiveOptions"
          allowClear
          @change="handleTableFilterChange()"
        />
      </template>

      <template #actions>
        <a-form-item>
          <a-button
            icon="undo"
            @click="handleTableFiltersReset()"
          >
            {{ $t("resetFilters") }}
          </a-button>
        </a-form-item>

        <a-form-item>
          <a-button
            icon="plus"
            type="primary"
            @click="onCreateModalOpen()"
          >
            {{ $t("addRecord") }}
          </a-button>
        </a-form-item>
      </template>
    </PageHeader>

    <a-table
      :columns="columns"
      :dataSource="dataSource"
      :scroll="{ x: true }"
      :loading="isFetching"
      :pagination="paginationInfo"
      rowKey="uuid"
      @change="onTableChange"
    >
      <template #locationRenderer="record, _, __, column">
        <span :class="{ 'secondary-text': !record.is_active }">
          {{ getLocation(record, column.key.toUpperCase()) }}
        </span>
      </template>

      <template #cityRenderer="record">
        <span
          v-if="isCity(record.type)"
          :class="{ 'secondary-text': !record.is_active }"
        >
          {{ record.name[getCurrentLocale()] }}
        </span>
      </template>

      <template #isActiveRenderer="isActive">
        <span :class="[isActive ? 'success-text' : 'secondary-text']">
          {{ isActive ? $t("active") : $t("hidden") }}
        </span>
      </template>

      <template #actionsRenderer="record">
        <div class="table-actions">
          <a-button
            type="dashed"
            shape="circle"
            icon="edit"
            @click="onEditModalOpen(record)"
          />
          <a-button
            type="dashed"
            shape="circle"
            :icon="record.is_active ? 'eye-invisible' : 'eye'"
            class="warning-button"
            @click="onActivityChange(record)"
          />

          <a-popconfirm
            :okText="$t('yes')"
            :cancelText="$t('no')"
            placement="topRight"
            @confirm="onDeleteLocation(record)"
          >
            <template #title>
              <div class="popconfirm-title">{{ $t("deletionWarningTitle") }}</div>
              <div>{{ $t("deletionWarningMessage") }}</div>
            </template>

            <a-button
              type="danger"
              shape="circle"
              icon="delete"
            />
          </a-popconfirm>
        </div>
      </template>
    </a-table>

    <a-drawer
      :visible="isCreateModalOpen"
      :title="$t('add')"
      :width="600"
      destroyOnClose
      @close="onCreateModalClose()"
    >
      <CreateLocationModal
        :countryOptions="countryLocationOptions"
        :stateOptions="stateLocationOptions"
        :districtOptions="districtLocations"
        @close="onCreateModalClose()"
        @onCreated="onCreateLocation()"
      />
    </a-drawer>

    <a-drawer
      :visible="isEditModalOpen"
      :title="$t('edit')"
      :width="600"
      destroyOnClose
      @close="onEditModalClose()"
    >
      <EditLocationModal
        :location="locationForEdit"
        @onUpdate="onUpdateLocation()"
      />
    </a-drawer>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue"
import { notification } from "ant-design-vue"
import i18n from "@/i18n"

import PageHeader from "@/ant-components/PageHeader/index.js"
import { EditLocationModal, CreateLocationModal } from "./components/index.js"

import {
  fetchAllCountryLocations,
  fetchAllDistrictLocations,
  fetchAllStateLocations,
  fetchLocations,
  updateLocation
} from "@/modules/MPAdmin/services/locationsService.js"

import getCurrentLocale from "@/utils/getCurrentLocale"

import { useStatuses } from "@/composables/useStatuses"
import { useLocationComposables } from "@/composables/useLocationComposables.js"
import useLocationsColumns from "@/modules/MPAdmin/pages/Locations/useLocationsColumns.js"
import useAntTableQuery from "@/composables/useAntTableQuery.js"
import notifyResponseError from "@/utils/notifyResponseError.js"

const { citiesColumns: columns } = useLocationsColumns()
const { isActiveStatuses } = useStatuses()
const { localityTypes } = useLocationComposables()

const {
  dataSource,
  isFetching,

  paginationInfo,
  filteredInfo,
  sortedInfo,

  setupTable,
  fetchTableInfo,
  setFilteredInfo,
  handleTableFilterChange,
  handleTableFiltersReset
} = useAntTableQuery({
  queryFunction: fetchLocations,
  requestParams: { changeRouterQuery: true }
})

const onTableChange = (pagination = paginationInfo.value, _, sorter = sortedInfo.value) => {
  fetchTableInfo({ pagination, filters: filteredInfo.value, sorter })
}

const isEditModalOpen = ref(false)
const isCreateModalOpen = ref(false)
const locationForEdit = ref()
const countryLocationOptions = ref([])
const stateLocationOptions = ref([])
const districtLocations = ref([])

const isActiveOptions = computed(() => Object.values(isActiveStatuses.value))
const localityTypesOptions = computed(() => localityTypes.value.slice(2))
const districtLocationsOptions = computed(() => {
  if (!filteredInfo.value.state) {
    return districtLocations.value
  }

  return districtLocations.value.filter(
    (district) => district?.parent?.uuid === filteredInfo.value.state
  )
})

const onActivityChange = async ({ uuid, is_active }) => {
  try {
    await updateLocation({ uuid, payload: { is_active: !is_active } })
    notification.success({ message: i18n.t("updated") })
    onTableChange()
  } catch (error) {
    notifyResponseError({ error })
  }
}

const onDeleteLocation = async ({ uuid }) => {
  try {
    await updateLocation({ uuid, payload: { is_deleted: true } })
    notification.success({ message: i18n.t("updated") })
    onTableChange()
  } catch (error) {
    notifyResponseError({ error })
  }
}

const onEditModalOpen = (location) => {
  locationForEdit.value = { ...location }
  isEditModalOpen.value = true
}

const onEditModalClose = () => {
  isEditModalOpen.value = false
  locationForEdit.value = undefined
}

const onUpdateLocation = () => {
  onEditModalClose()
  onTableChange()
}

const onCreateModalOpen = () => {
  isCreateModalOpen.value = true
}

const onCreateModalClose = () => {
  isCreateModalOpen.value = false
}

const onCreateLocation = () => {
  onCreateModalClose()
  onTableChange()
}

const getLocation = (record, type) => {
  let location = record

  if (location.type === type) {
    return location.name[getCurrentLocale()]
  }

  if (location.parent) {
    return getLocation(location.parent, type)
  }

  return ""
}

const isCity = (type) => {
  const notCityArray = ["COUNTRY", "STATE", "DISTRICT"]
  return !notCityArray.includes(type)
}

const getFilterOptions = async () => {
  try {
    const { data: countries } = await fetchAllCountryLocations()

    const options = countries.results.map(({ uuid, name }) => ({
      title: name[getCurrentLocale()],
      value: uuid
    }))

    countryLocationOptions.value = options
  } catch (error) {
    notifyResponseError({ error })
  }

  try {
    const { data: states } = await fetchAllStateLocations()

    const options = states.results.map(({ uuid, name }) => ({
      title: name[getCurrentLocale()],
      value: uuid
    }))

    stateLocationOptions.value = options
  } catch (error) {
    notifyResponseError({ error })
  }

  try {
    const { data: districts } = await fetchAllDistrictLocations()

    const options = districts.results.map(({ uuid, name, parent }) => ({
      title: name[getCurrentLocale()],
      value: uuid,
      parent
    }))

    districtLocations.value = options
  } catch (error) {
    notifyResponseError({ error })
  }
}

const onStateChange = () => {
  if (filteredInfo.value.district) {
    const { district, ...filters } = filteredInfo.value
    setFilteredInfo(filters)
  }
  handleTableFilterChange()
}

onMounted(() => {
  setupTable()
  getFilterOptions()
  onTableChange()
})
</script>

<style lang="scss" scoped>
.table-actions {
  display: flex;
  gap: 8px;
}

.success-text {
  color: $green-color;
}

.secondary-text {
  opacity: 0.45;
}

.warning-button {
  color: $dark-yellow-color;
  border-color: $dark-yellow-color;
  accent-color: $dark-yellow-color;

  &:hover,
  &:active {
    color: color-mix(in srgb, $dark-yellow-color 50%, white);
    border-color: color-mix(in srgb, $dark-yellow-color 50%, white);
    accent-color: color-mix(in srgb, $dark-yellow-color 50%, white);
  }
}

.popconfirm-title {
  font-weight: 500;
  max-width: 180px;
}
</style>
