import productPage from "./productPage"
import brandsPage from "./brandsPage"
import typesPage from "./typesPage"
import unitsPage from "./unitsPage"
import locale from "./locale"

import { notification } from "ant-design-vue"

const state = () => ({
  PC_showLoader: false
})
const getters = {
  PC_showLoader: (state) => state.PC_showLoader
}

const mutations = {
  PC_showLoader: (state, value) => {
    state.PC_showLoader = value
  },
  handleError(state, e) {
    console.log(`Error in action "${e.$_actionName}": ${e.message}`)
    let msg = ""
    if (e.message && e.response && e.response.status) {
      msg = `(${e.response.status}) ${e.message}`
    } else {
      msg = "Ошибка соединения"
    }
    notification.error({ message: msg })
  }
}

const actions = {}

const modules = {
  productPage,
  brandsPage,
  typesPage,
  unitsPage,
  locale
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
  modules
}
