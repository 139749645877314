<template>
  <a-form-model
    class="create-attribute-modal"
    ref="formRef"
    :model="form"
    :rules="formRules"
  >
    <a-form-model-item
      :label="$t('workName')"
      prop="name"
    >
      <a-input v-model="form.name" />
    </a-form-model-item>

    <a-form-model-item
      :label="`${$t('publicName')} (UA)`"
      prop="public_name.uk"
    >
      <a-input v-model="form.public_name.uk" />
    </a-form-model-item>

    <a-form-model-item
      :label="`${$t('publicName')} (RU)`"
      prop="public_name.ru"
    >
      <a-input v-model="form.public_name.ru" />
    </a-form-model-item>

    <a-form-model-item
      :label="`${$t('publicName')} (EN)`"
      prop="public_name.en"
    >
      <a-input v-model="form.public_name.en" />
    </a-form-model-item>

    <a-form-model-item
      class="create-attribute-modal__row__item"
      :label="$t('valueType')"
      prop="value_type"
    >
      <a-select
        :options="attributeTypeOptions"
        v-model="form.value_type"
        @change="onChangeType"
      />
    </a-form-model-item>

    <a-form-model-item
      v-show="showUnit"
      class="create-attribute-modal__row__item"
      :label="$t('unit')"
      prop="default_unit"
    >
      <InfiniteScrollSelect
        :value="form.default_unit"
        :customRequest="fetchUnits"
        labelKey="name"
        valueKey="oid"
        @change="onUnitChange"
      />
    </a-form-model-item>

    <div class="drawer-footer">
      <a-button
        type="primary"
        :loading="isFetching"
        @click="createNewAttribute"
      >
        {{ $t("create") }}
      </a-button>
    </div>
  </a-form-model>
</template>

<script setup>
import { computed, inject, reactive, ref } from "vue"
import { notification } from "ant-design-vue"
import i18n from "@/i18n"

import InfiniteScrollSelect from "@/ant-components/InfiniteScrollSelect/InfiniteScrollSelect.vue"

import useProductAttributeComposables from "../composables/useProductAttributeComposables.js"

import {
  createAttribute,
  updateAttribute
} from "@/modules/Moderation/services/attributesService.js"
import { fetchUnits } from "@/modules/Moderation/services/unitsService.js"
import notifyResponseError from "@/utils/notifyResponseError.js"

const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits(["close", "created"])

const { attributeTypeOptions } = useProductAttributeComposables()

const formRef = ref()
const form = reactive({
  name: "",
  public_name: {
    uk: "",
    ru: "",
    en: ""
  },
  description: "",
  value_type: "STRING",
  product_type: null,
  default_unit: null
})
const formRules = computed(() => ({
  name: [
    { required: true, message: i18n.t("emptyError") },
    { max: 50, message: i18n.t("maxLengthError", { length: 50 }) }
  ],
  public_name: {
    uk: [
      { required: true, message: i18n.t("emptyError") },
      { max: 50, message: i18n.t("maxLengthError", { length: 50 }) }
    ],
    ru: [
      { required: true, message: i18n.t("emptyError") },
      { max: 50, message: i18n.t("maxLengthError", { length: 50 }) }
    ],
    en: [
      { required: true, message: i18n.t("emptyError") },
      { max: 50, message: i18n.t("maxLengthError", { length: 50 }) }
    ]
  },
  value_type: { required: true, message: i18n.t("emptyError") }
}))

const isFetching = ref(false)
const showUnit = computed(() => form.value_type === "NUMERIC")

const onChangeType = async () => {
  form.product_type = null
}

const onUnitChange = (value) => {
  form.default_unit = value
}

const createNewAttribute = async () => {
  try {
    await formRef.value.validate()
  } catch (e) {
    return
  }

  try {
    isFetching.value = true

    const { default_unit, ...payload } = form

    if (payload.value_type === "NUMERIC") {
      payload.default_unit = default_unit || null
    } else {
      payload.default_unit = null
    }

    const { data } = await createAttribute({
      payload: { ...payload, public_name: form.public_name.ru }
    })

    await updateAttribute({
      code: data.code,
      payload: { public_name: form.public_name.uk },
      language: "uk"
    })

    await updateAttribute({
      code: data.code,
      payload: { public_name: form.public_name.en },
      language: "en"
    })

    notification.success({
      message: i18n.t("created")
    })
    emit("created", data)
    formRef.value.resetFields()
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    isFetching.value = false
  }
}
</script>

<style lang="scss" scoped>
.create-attribute-modal {
  padding-bottom: 50px;

  &__row {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 16px;

    &__item {
      width: calc(50% - 8px);
    }
  }
}
</style>
