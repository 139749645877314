<template>
  <a-select
    ref="infiniteSelectRef"
    :value.trim="props.value"
    :mode="props.mode"
    :maxTagTextLength="30"
    :showSearch="props.showSearch"
    :allowClear="props.allowClear"
    :loading="isFetching"
    :filterOption="false"
    :placeholder="props.placeholder"
    :dropdownStyle="{ maxHeight: '300px', overflow: 'hidden' }"
    :dropdownMenuStyle="{ maxHeight: '250px', overflow: 'auto' }"
    @change="onChange"
    @focus="onFocus"
    @blur="onBlur"
    @popupScroll="onScroll"
    @search="onSearch"
  >
    <template #dropdownRender="menu">
      <SelectNodes :vnodes="menu" />

      <div
        v-show="props.isLoading || isFetching"
        class="select-loader"
      />

      <slot name="extraButton" />
    </template>

    <slot name="options">
      <a-select-option
        v-for="option in selectOptions"
        :key="option[props.valueKey]"
        :value="option[props.valueKey]"
      >
        <slot
          name="option"
          :option="option"
        >
          {{ option[props.labelKey] }}
        </slot>
      </a-select-option>
    </slot>
  </a-select>
</template>

<script setup>
import useCancellableRequest from "@/composables/useCancellableRequest"
import notifyResponseError from "@/utils/notifyResponseError"
import { computed, defineComponent, ref, shallowRef } from "vue"

const SelectNodes = defineComponent({
  props: {
    vnodes: {
      type: Object,
      required: true
    }
  },
  render() {
    return this.vnodes
  }
})

const props = defineProps({
  value: {
    type: String | Array,
    default: undefined
  },
  mode: {
    type: "tags" | "multiple" | "default",
    default: "default"
  },
  defaultOptions: {
    type: Array,
    default: () => []
  },
  isLoading: {
    type: Boolean,
    default: false
  },
  customRequest: {
    type: Function | undefined,
    default: undefined
  },
  customRequestParams: {
    type: Object,
    default: () => ({})
  },
  valueKey: {
    type: String,
    default: "oid"
  },
  labelKey: {
    type: String,
    default: "name"
  },
  searchKey: {
    type: String,
    default: ""
  },
  allowClear: {
    type: Boolean,
    default: true
  },
  placeholder: {
    type: String,
    default: ""
  },
  onFocusFetch: {
    type: Boolean,
    default: false
  },
  customRequest: {
    type: Function | undefined,
    default: undefined
  },
  customRequestParams: {
    type: Object,
    default: () => ({})
  },
  valueKey: {
    type: String,
    default: "oid"
  },
  labelKey: {
    type: String,
    default: "name"
  },
  searchKey: {
    type: String,
    default: ""
  },
  allowClear: {
    type: Boolean,
    default: true
  },
  placeholder: {
    type: String,
    default: ""
  },
  onFocusFetch: {
    type: Boolean,
    default: false
  },
  showSearch: {
    type: Boolean,
    default: true
  }
})
const emit = defineEmits(["change", "focus", "blur", "scroll", "search"])

const { makeRequest } = useCancellableRequest(props.customRequest)

const searchTimeout = ref()
const infiniteSelectRef = ref()

const onChange = (value) => {
  emit("change", value)
}

const onFocus = () => {
  emit("focus")
  if (!props.onFocusFetch || dataSource.value.length) return

  handleSearchOptions()
}

const onBlur = () => {
  lastSearchedValue.value = ""
  emit("blur")
}

const isFetching = shallowRef(false)
const lastSearchedValue = ref("")
const dataSource = ref([])
const dataSourceCount = ref()

const selectOptions = computed(() => {
  if (!props.defaultOptions.length) return dataSource.value
  return [
    ...props.defaultOptions,
    ...dataSource.value.filter(
      (item) =>
        !props.defaultOptions.find((option) => option[props.valueKey] === item[props.valueKey])
    )
  ]
})

const onSearch = (value) => {
  if (searchTimeout.value) {
    clearTimeout(searchTimeout.value)
    searchTimeout.value = undefined
  }

  lastSearchedValue.value = value.trim()

  searchTimeout.value = setTimeout(() => {
    if (!props.customRequest) return emit("search", lastSearchedValue.value)

    dataSource.value = []
    dataSourceCount.value = undefined

    handleSearchOptions()

    searchTimeout.value = undefined
  }, 500)
}

const handleSearchOptions = async () => {
  try {
    isFetching.value = true

    const searchKey = props.searchKey || props.labelKey

    const { data } = await makeRequest({
      queryParams: {
        ...props.customRequestParams,
        [searchKey]: lastSearchedValue.value,
        pageSize: 10,
        page: Math.floor(dataSource.value.length / 10) + 1
      }
    })
    dataSource.value.push(...data.results)
    dataSourceCount.value = data.count
    isFetching.value = false
  } catch (error) {
    if (error.message === "canceled") return
    isFetching.value = false
    notifyResponseError({ error })
  }
}

const onScroll = async (event) => {
  if (event.target?.scrollHeight - event.target?.clientHeight > event.target?.scrollTop + 10) return

  if (!props.customRequest) return emit("scroll")

  if (isFetching.value) return
  if (dataSource.value.length >= dataSourceCount.value) return
  handleSearchOptions()
}

const getInnerText = () => {
  return infiniteSelectRef.value.$el.innerText
}

defineExpose({
  getInnerText,
  selectOptions
})
</script>

<style lang="scss" scoped>
.select-loader {
  height: 2px;
  width: 100%;
  background:
    no-repeat linear-gradient(#40a9ff 0 0),
    no-repeat linear-gradient(#40a9ff 0 0),
    transparent;
  background-size: 60% 100%;
  animation: l16 3s infinite;

  position: absolute;
  bottom: 0;
  left: 0;
}
@keyframes l16 {
  0% {
    background-position:
      -150% 0,
      -150% 0;
  }
  66% {
    background-position:
      250% 0,
      -150% 0;
  }
  100% {
    background-position:
      250% 0,
      250% 0;
  }
}
</style>
