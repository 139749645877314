import { API_V2 } from "@/utils/HttpUtils.js"
import { transformQuery, moderationPrefix, getLocalizedHeaders } from "./servicesUtils.js"

export const fetchAttributeValuesList = ({ queryParams, requestParams, signal }) => {
  return API_V2.get(
    `${moderationPrefix}/attribute-values/${transformQuery(
      queryParams,
      requestParams?.changeRouterQuery
    )}`,
    getLocalizedHeaders(requestParams?.language?.value, signal)
  )
}

export const fetchLocalisedAttributeValuesList = async ({ queryParams, requestParams, signal }) => {
  if (requestParams.valueType === "NUMERIC") {
    return fetchAttributeValuesList({ queryParams, requestParams, signal })
  }

  try {
    const ruResponse = await fetchAttributeValuesList({
      queryParams,
      requestParams: { language: { value: "ru" } },
      signal
    })
    const ukResponse = await fetchAttributeValuesList({
      queryParams,
      requestParams: { language: { value: "uk" } },
      signal
    })

    const enResponse = await fetchAttributeValuesList({
      queryParams,
      requestParams: { language: { value: "en" } },
      signal
    })
    ruResponse.data.results = ruResponse.data.results.map((record, index) => ({
      ...record,
      value: {
        ru: record.value,
        uk: ukResponse.data.results[index].value,
        en: enResponse.data.results[index].value
      }
    }))

    return Promise.resolve(ruResponse)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const createValueForAttribute = ({ payload }) => {
  return API_V2.post(`${moderationPrefix}/attribute-values/`, payload, getLocalizedHeaders("ru"))
}

export const updateValueForAttribute = ({ payload, code, language }) => {
  return API_V2.put(
    `${moderationPrefix}/attribute-values/${code}/`,
    payload,
    getLocalizedHeaders(language)
  )
}

export const updateLocalizedValueForAttribute = async ({ payload, code }) => {
  try {
    const { value } = payload
    const ruPayload = { ...payload, value: value.ru }
    const ukPayload = { value: value.uk }
    const enPayload = { value: value.en }

    await updateValueForAttribute({ payload: ruPayload, code, language: "ru" })
    await updateValueForAttribute({ payload: ukPayload, code, language: "uk" })
    await updateValueForAttribute({ payload: enPayload, code, language: "en" })

    return Promise.resolve(payload)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const createNewLocalisedAttributeValue = async (payload) => {
  const { value, ...ruPayload } = payload
  const { uk, ru, en } = value

  try {
    const responseRu = await createValueForAttribute({ payload: { ...ruPayload, value: ru } })

    await updateValueForAttribute({
      code: responseRu.data.code,
      payload: { value: uk },
      language: "uk"
    })
    await updateValueForAttribute({
      code: responseRu.data.code,
      payload: { value: en },
      language: "en"
    })

    return Promise.resolve(responseRu.data)
  } catch (error) {
    return Promise.reject(error)
  }
}

export const deleteValueForAttribute = ({ code }) => {
  return API_V2.delete(`${moderationPrefix}/attribute-values/${code}/`)
}

export const importAttributeValuesFile = (file) => {
  const payload = new FormData()
  payload.append("file", file)

  return API_V2.post(`${moderationPrefix}/attribute-values/imports/`, payload)
}
