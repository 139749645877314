<template>
  <div class="attribute-value">
    <InfiniteScrollSelect
      ref="selectRef"
      :value="attributeValue"
      :mode="isAttributeMultiple ? 'multiple' : 'default'"
      :customRequest="fetchAttributeValuesList"
      :customRequestParams="{ attribute: props.attribute.code }"
      :defaultOptions="valuesOptions"
      labelKey="value"
      valueKey="code"
      searchKey="value_str"
      onFocusFetch
      @change="onChange"
    >
      <template #extraButton>
        <a-divider style="padding: 0; margin: 0" />
        <a-button
          block
          type="link"
          icon="plus"
          style="padding: 0"
          @click="openCreateValueModal()"
        >
          {{ $t("addNewValue") }}
        </a-button>
      </template>

      <template #option="{ option }">
        {{ option.value }}
        <template v-if="option.unit">{{ option.unit.name }}</template>
      </template>
    </InfiniteScrollSelect>

    <a-modal
      v-if="showCreateValue"
      :visible="true"
      @cancel="closeCreateValueModal"
    >
      <AddValueForm
        :attributeCode="attribute.code"
        :attributeType="attribute.value_type"
        :attributeUnit="attribute.default_unit"
        :defaultUnits="numericPossibleUnits"
        :defaultValue="defaultNewValue"
        @submit="onNewValueCreated"
        @close="closeCreateValueModal"
      />
    </a-modal>
  </div>
</template>

<script setup>
import { onMounted, ref, computed, shallowRef } from "vue"
import notifyResponseError from "@/utils/notifyResponseError"
import { fetchAttributeValuesList } from "@/modules/Moderation/services/attributeValuesService"
import InfiniteScrollSelect from "@/ant-components/InfiniteScrollSelect/InfiniteScrollSelect.vue"
import AddValueForm from "@/modules/Moderation/Attributes/EditAttribute/values/AddValueForm.vue"
import { fetchAttributeUnits } from "@/modules/Moderation/services/attributesService"

const props = defineProps({
  attribute: {
    type: Object,
    required: true
  }
})

const emit = defineEmits(["change"])

const selectRef = ref()

const attributeValue = ref()
const valuesOptions = ref([])

const isAttributeMultiple = computed(() => Boolean(props.attribute.allow_multiple))

const findValueObject = (value) => {
  return (
    selectRef.value.selectOptions.find(({ code }) => code === value) ||
    props.attribute.value.find(({ code }) => code === value)
  )
}

const onChange = (value) => {
  attributeValue.value = value

  if (isAttributeMultiple.value) {
    attributeValue.value = value

    emit("change", {
      code: props.attribute.code,
      value: value.map((value) => findValueObject(value)).filter(Boolean)
    })
    return
  }

  const attrValue = findValueObject(value)

  emit("change", { code: props.attribute.code, value: attrValue ? [attrValue] : [] })
}

const showCreateValue = shallowRef(false)
const numericPossibleUnits = ref([])
const defaultNewValue = shallowRef(undefined)
const openCreateValueModal = async () => {
  defaultNewValue.value = selectRef.value?.getInnerText()?.split("\n")?.at(-1) || ""
  if (props.attribute.value_type === "NUMERIC") {
    try {
      const { data } = await fetchAttributeUnits(props.attribute.code)
      numericPossibleUnits.value = data.results
    } catch (error) {
      notifyResponseError({ error })
    }
  }

  showCreateValue.value = true
}
const closeCreateValueModal = () => {
  showCreateValue.value = false
}
const onNewValueCreated = (value) => {
  valuesOptions.value.unshift(value)

  const newValue = isAttributeMultiple.value ? [...attributeValue.value, value.code] : value.code
  onChange(newValue)
}

const resetForm = () => {
  if (!props.attribute.value) return

  if (isAttributeMultiple.value) {
    attributeValue.value = props.attribute.value?.map((option) => option.code)
    valuesOptions.value = props.attribute.value?.some(({ value }) => Boolean(value))
      ? props.attribute.value
      : []
  } else {
    attributeValue.value = props.attribute?.value[0]?.code || undefined
    valuesOptions.value = props.attribute.value ? props.attribute.value : []
  }
}

onMounted(resetForm)
</script>

<style lang="scss" scoped>
.attribute-value {
  width: 100%;

  & * {
    width: 100%;
  }

  &__radio {
    display: flex;

    & * {
      width: fit-content;
    }
  }
}
</style>
