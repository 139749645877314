export default {
  namespaced: true,
  state: {
    unreadMessages: null
  },
  mutations: {
    SET_UNREAD_MESSAGES(state, count) {
      state.unreadMessages = count
    }
  },
  actions: {
    setUnreadMessages({ commit }, count) {
      commit("SET_UNREAD_MESSAGES", count)
    }
  }
}
