import i18n from "@/i18n"
import { computed } from "vue"

export default () => {
  const columns = computed(() => [
    {
      key: "full_name",
      dataIndex: "full_name",
      title: i18n.t("typesByAttribute")
    },
    {
      key: "is_main",
      scopedSlots: { customRender: "isMainRenderer", title: "isMainTitleRenderer" },
      width: 64
    },
    {
      key: "is_required",
      scopedSlots: { customRender: "isRequiredRenderer", title: "isRequiredTitleRenderer" },
      width: 64
    },
    {
      key: "actions",
      scopedSlots: { customRender: "actions" },
      width: 64
    }
  ])

  return { columns }
}
