var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"message__main",class:{ message__self: _vm.isSelf }},[_c('a-avatar',{staticClass:"message__avatar",attrs:{"loadError":() => false,"size":50,"src":_vm.userAvatarAbsolutePath}}),_c('div',{staticClass:"message__align-center"},[_c('div',{staticClass:"message__info",class:{ 'message__info--notification': _vm.message.type === 'NOTIFICATION' }},[(!_vm.isRemoved)?_c('div',{staticClass:"message__info-header"},[_c('p',{staticClass:"message__name"},[_vm._v(" "+_vm._s(_vm.username)+" ")]),_c('p',{staticClass:"message__time"},[_vm._v(" "+_vm._s(_vm.createdAt)+" ")])]):_vm._e(),_c('div',{staticClass:"message__content"},[(_vm.isRemoved)?_c('div',[_c('a-icon',{staticStyle:{"font-size":"14px"},attrs:{"type":"delete"}}),_vm._v(" "+_vm._s(_vm.$t("messageDeleted"))+" ")],1):(_vm.messageBody)?_c('div',[_c('div',{staticClass:"content__text"},[(!_vm.mediaContent.length)?_c('pre',[_vm._v(_vm._s(_vm.messageBody))]):_vm._l((_vm.mediaContent),function(record,index){return _c('span',{key:index},[(record.type === 'pCode')?_c('a',{attrs:{"href":_setup.getProductUrlByProductCode(record.body),"target":"_blank"}},[_vm._v(_vm._s(record.body))]):(record.type === 'link')?_c('a',{attrs:{"href":record.body,"target":"_blank"}},[_vm._v(_vm._s(record.body))]):_c('pre',[_vm._v(_vm._s(record.body))])])})],2)]):_vm._e(),(_vm.message.images && _vm.message.images.length)?[_c('div',{staticClass:"content__images"},_vm._l((_vm.message.images),function(img,index){return _c('span',{key:index,staticClass:"content__image-container"},[_c('img',{staticClass:"content__image",attrs:{"src":img.file,"alt":"img"},on:{"load":_setup.onLoad,"click":function($event){_setup.openGallery({
                    images: _vm.message.images.map((img) => img.file),
                    startIndex: index
                  })}}}),_c('span',{staticClass:"content__images-download-wrap",on:{"click":function($event){return _vm.$emit('loadImage', img.file)}}},[_c('a-icon',{staticClass:"content__images-download",attrs:{"type":"download"}})],1)])}),0)]:_vm._e(),(_vm.message.files && _vm.message.files.length)?_vm._l((_vm.message.files),function(file,index){return _c('p',{key:`${file}-${index}`,staticClass:"content__file"},[_c('a',{on:{"click":function($event){$event.preventDefault();return _vm.$emit('loadFile', file.file)}}},[_c('a-avatar',{staticClass:"file__icon",attrs:{"size":32,"icon":"file"}}),_c('div',[_c('div',{staticClass:"file__name"},[_vm._v(_vm._s(file.name_original))]),_c('span',[_vm._v(_vm._s(_vm.$t("download")))])])],1)])}):_vm._e(),(!_vm.isRemoved)?_c('span',{staticClass:"message__status"},[(!_vm.isRemoved && _vm.message.status === 'read')?_c('span',{staticStyle:{"padding-left":"8px"}},[_c('a-icon',{staticClass:"message__status-read",staticStyle:{"margin-right":"-6px"},attrs:{"type":"check"}}),_c('a-icon',{staticClass:"message__status-read",attrs:{"type":"check"}})],1):(!_vm.isRemoved && _vm.message.status === 'sent')?_c('a-icon',{staticClass:"message__status-sent",attrs:{"type":"check"}}):_vm._e(),(_vm.isEdited || _vm.isRemoved)?_c('span',[(_vm.isEdited)?[_vm._v(_vm._s(_vm.$t("edited")))]:_vm._e(),(_vm.isEdited)?_c('span',{staticClass:"message__edit-history"},[_vm._v(" ("),_c('a',{on:{"click":function($event){$event.preventDefault();return _vm.$emit('showEditHistory', {
                    messageUuid: _vm.message.uuid,
                    originalMessageBody: _vm.messageBody
                  })}}},[_vm._v(_vm._s(_vm.$t("changeHistory").toLowerCase()))]),_vm._v(") ")]):_vm._e(),(_vm.isRemoved)?[_vm._v(_vm._s(_vm.$t("messageDeleted")))]:_vm._e()],2):_vm._e()],1):_vm._e()],2)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }