<template>
  <div
    class="users"
    ref="usersContainerRef"
    @mousedown.prevent
  >
    <div
      v-for="chat in chats"
      :key="chat.uuid"
      class="users__user user"
      :class="{ 'users__user--active': chat.uuid === currentChatId }"
      @click="onChatClick(chat.uuid)"
    >
      <div class="user__avatar">
        <div class="user__avatar-wrapper">
          <a-icon
            type="user"
            style="font-size: 18px"
          />
        </div>
      </div>
      <div class="user__description">
        <div class="user__message-meta">
          <div class="user__name">
            <div
              v-if="isSeller(chat)"
              class="user__label-seller"
            >
              {{ $t("seller").charAt(0) }}
            </div>
            {{ chat.title }}
          </div>
          <div
            class="user__time"
            v-if="chat.last_message"
          >
            {{ getDynamicDateFormat(chat.last_message.created_at) }}
          </div>
        </div>
        <div
          v-if="chat.last_message"
          class="user__message-preview"
          :class="{ 'user__message-preview--unread': chat.unread_count > 0 }"
        >
          <div class="user__last-message">
            <template v-if="chat.last_message.is_removed">
              <a-icon
                type="delete"
                :style="{ color: '#161616', margin: '0 0 0 4px' }"
              />
              {{ $t("messageDeleted") }}
            </template>
            <template v-else-if="chat.last_message.type === 'SIMPLE'">
              {{ chat.last_message.body }}
            </template>
            <template
              v-if="
                chat.last_message.type === 'WITH_ATTACHMENTS' &&
                chat.last_message.attachments.length > 0
              "
            >
              <a-icon
                type="paper-clip"
                :style="{ color: '#161616', margin: '0 0 0 4px' }"
              />
              {{
                chat.last_message.attachments[0].name_original ||
                chat.last_message.attachments[0].name
              }}
            </template>
          </div>
          <div
            v-if="chat.unread_count > 0"
            class="user__messages-counter"
          >
            {{ chat.unread_count }}
          </div>
        </div>
      </div>
    </div>

    <a-empty
      v-if="!chats.length && !isFetching"
      style="margin-top: 32px"
    />

    <a-spin :spinning="isFetching" />
  </div>
</template>

<script setup>
import { globalChatSearch } from "@/utils/messengerXhrService"
import { ref, onMounted, onBeforeUnmount } from "vue"
import { getDynamicDateFormat } from "@/utils/getFormatedDateTime"
import notifyResponseError from "@/utils/notifyResponseError"

const props = defineProps({
  currentChatId: {
    type: String,
    default: ""
  },
  form: {
    type: Object,
    default: () => ({})
  }
})
const emit = defineEmits(["openChat"])

const usersContainerRef = ref()

const isFetching = ref(false)
const chats = ref([])
const hasNextPage = ref(true)

const isSeller = (chat) => {
  const myselfIsChatOwner = chat.owner.uuid === props.form.secondInput

  if (myselfIsChatOwner) {
    return chat.participants.some((participant) => participant.role === "seller")
  } else {
    return chat.owner.role === "seller"
  }
}

const onChatClick = (uuid) => {
  emit("openChat", uuid)
}

const onScrollBottom = () => {
  if (isFetching.value) return

  const { scrollTop, scrollHeight, clientHeight } = usersContainerRef.value
  const isNotScrolled = scrollTop === 0

  if (isNotScrolled) return

  const scrolledToBottom = scrollTop >= scrollHeight - clientHeight - 50

  if (scrolledToBottom && hasNextPage.value) {
    getChats()
  }
}

const getChats = async () => {
  try {
    isFetching.value = true

    const participant = [props.form.firstInput, props.form.secondInput].filter(Boolean).join(",")

    const { data, config } = await globalChatSearch({
      participant,
      limit: 15,
      offset: chats.value.length
    })

    if (!data.count) {
      return resetChats()
    }

    const currentChat = chats.value.find((c) => c.uuid === props.currentChatId)
    const currentChatMessages = currentChat ? currentChat.messages : []

    const newChats = data.results.map((item) => {
      let messages = []

      if (item.uuid === props.currentChatId) {
        messages = currentChatMessages
      }

      return {
        ...item,
        id: item.uuid,
        user: {
          online: item.owner.is_online,
          name: item.owner.username
        },
        messages,
        offset: 0
      }
    })

    if (chats.value.length) {
      chats.value.push(...newChats)
    } else {
      chats.value = newChats
    }

    const totalFetchedChats = config.params.limit + config.params.offset
    hasNextPage.value = data.count - totalFetchedChats > 0
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    isFetching.value = false
  }
}

const resetChats = () => {
  chats.value = []
}

onMounted(() => {
  usersContainerRef.value.addEventListener("scroll", onScrollBottom, false)
})

onBeforeUnmount(() => {
  usersContainerRef.value?.removeEventListener("scroll", onScrollBottom, false)
})

defineExpose({
  getChats,
  resetChats
})
</script>

<style lang="scss" scoped>
.users {
  flex-shrink: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  max-height: calc(100% - 56px);
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #bfbfbf;

    &:hover {
      background-color: #8c8c8c;
    }
  }

  &__user {
    position: relative;
    border-top: 1px solid #eee;

    &:first-child {
      border-top: 0;
    }

    &--active {
      background-color: #f5f5f5;

      &:before {
        position: absolute;
        background-color: #685f74;
        border-bottom-right-radius: 4px;
        border-top-right-radius: 4px;
        width: 4px;
        content: "";
        height: 100%;
        left: 0;
        top: 0;
      }
    }
  }

  &__loader {
    padding: 16px 12px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
  }
}

.user {
  padding: 0 16px;
  min-height: 96px;
  cursor: pointer;
  display: flex;
  align-items: center;

  &__avatar {
    min-width: 48px;
    min-height: 48px;
    width: 48px;
    height: 48px;
    position: relative;
    margin-right: 12px;
    background-color: transparent;
    display: flex;

    &-wrapper {
      background-color: #fff;
      min-width: 48px;
      min-height: 48px;
      width: 48px;
      height: 48px;
      border-radius: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      & > img {
        max-width: 100%;
        max-height: 100%;
      }
    }

    &--online,
    &--offline {
      &:after {
        width: 14px;
        height: 14px;
        border-radius: 7px;
        display: inline-block;
        position: absolute;
        top: -6px;
        left: -6px;
        border: 3px solid #fff;
        content: "";
      }
    }

    &--online {
      &:after {
        background-color: #7cb305;
      }
    }

    &--offline {
      &:after {
        background-color: #ff4d4f;
      }
    }
  }

  &__description {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: calc(100% - 48px - 12px);
  }

  &__label-seller {
    background-color: #006d75;
    color: #e6fffb;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 0 4px;
    font-size: 12px;
    line-height: 18px;
    display: inline-block;
    margin-right: 4px;
    font-weight: 400;
  }

  &__message-meta {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__name {
    font-size: 12px;
    line-height: 18px;
    color: #161616;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    word-break: break-all;
    font-weight: 500;
  }

  &__time {
    color: #8c8c8c;
    font-size: 12px;
    line-height: 18px;
    padding-left: 8px;
    white-space: nowrap;
    display: inline-block;
  }

  &__message-preview {
    font-size: 12px;
    line-height: 18px;
    display: flex;
    justify-content: space-between;

    &--unread {
      font-weight: 500;

      & > .user__last-message {
        color: #141414;
      }
    }
  }

  &__myself {
    color: #8c8c8c;
    font-weight: 400;
    display: inline;
  }

  &__last-message {
    color: #434343;
    padding-right: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    word-break: break-all;
  }

  &__messages-counter {
    background-color: #389e0d;
    flex-shrink: 0;
    color: #f6ffed;
    border-radius: 9px;
    padding: 0 4px;
    font-size: 12px;
    line-height: 18px;
    display: inline-block;
    font-weight: 500;
    min-width: 18px;
    text-align: center;
  }
}
</style>
