<template>
  <div class="spv-messenger">
    <div class="moderator-search">
      <PageHeader :title="$t('messagesModeration')">
        <template #filters>
          <a-input-search
            :placeholder="$t('firstUser')"
            v-model="form.firstInput"
            allowClear
            @search="onSearch"
          >
            <template #enterButton>
              <a-button icon="search" />
            </template>
          </a-input-search>

          <a-input-search
            :placeholder="$t('secondUser')"
            v-model="form.secondInput"
            allowClear
            @search="onSearch"
          >
            <template #enterButton>
              <a-button icon="search" />
            </template>
          </a-input-search>

          <a-button
            type="primary"
            :disabled="!enableButton"
            style="width: fit-content"
            @click="onSearch"
          >
            {{ $t("search") }}
          </a-button>
        </template>
      </PageHeader>

      <div class="moderator-messenger">
        <div class="messenger-sidebar">
          <SidePannelUsers
            ref="sidePannelUsersRef"
            isModeratorSearch
            :form="form"
            style="height: 100%; max-height: unset"
            @openChat="onOpenChat"
          />
        </div>
        <div class="messenger-content">
          <MessagesDisplay
            moderatorSearchComponent
            :currentChatId="currentChatId"
            @showEditHistory="onShowEditHistory"
          />
        </div>
      </div>
    </div>

    <MessageHistoryModal
      :visible="showEditHistory"
      :hasNextPage="showEditHistoryHasNextPage"
      :editHistory="editHistory"
      :originalMessageBody="showEditHistoryOriginalMessageBody"
      @getNextPage="getEditHistoryNextPage"
      @close="onCloseEditHistory"
    />
  </div>
</template>

<script setup>
import MessagesDisplay from "./MessagesDisplay"
import SidePannelUsers from "./SidePannelUsers.vue"
import MessageHistoryModal from "./MessageHistoryModal"
import { getMessageHistory } from "@/utils/messengerXhrService"
import PageHeader from "@/ant-components/PageHeader/PageHeader.vue"
import { computed, reactive, ref } from "vue"
import notifyResponseError from "@/utils/notifyResponseError"

const sidePannelUsersRef = ref()

const form = reactive({
  firstInput: "",
  secondInput: ""
})

const showEditHistory = ref(false)
const showEditHistoryMessageUuid = ref("")
const showEditHistoryLimit = ref(15)
const showEditHistoryHasNextPage = ref(false)
const showEditHistoryOriginalMessageBody = ref("")
const editHistory = ref([])
const currentChatId = ref("")

const enableButton = computed(() => {
  return form.firstInput || form.secondInput
})

const onSearch = () => {
  sidePannelUsersRef.value.resetChats()
  sidePannelUsersRef.value.getChats()

  currentChatId.value = undefined
}
const onShowEditHistory = ({ messageUuid, originalMessageBody }) => {
  showEditHistory.value = true
  showEditHistoryMessageUuid.value = messageUuid
  showEditHistoryOriginalMessageBody.value = originalMessageBody

  getEditHistoryNextPage()
}
const getEditHistoryNextPage = async () => {
  try {
    const { data } = await getMessageHistory({
      messageUuid: showEditHistoryMessageUuid.value,
      limit: showEditHistoryLimit.value,
      offset: editHistory.value.length
    })

    editHistory.value.push(...data.results)

    if (editHistory.value.length < data.count) {
      showEditHistoryHasNextPage.value = true
    }
  } catch (error) {
    notifyResponseError(error)
  }
}

const onCloseEditHistory = () => {
  showEditHistory.value = false
  showEditHistoryMessageUuid.value = ""
  showEditHistoryLimit.value = 15
  showEditHistoryHasNextPage.value = false
  showEditHistoryOriginalMessageBody.value = ""
  editHistory.value = []
}

const onOpenChat = (chatId) => {
  currentChatId.value = chatId
}
</script>

<style lang="scss" scoped>
.moderator-messenger {
  width: 100%;
  height: calc(100vh - 220px);
  border-radius: 7px;
  overflow: hidden;
  border: 1px solid #d9d9d9;
  display: flex;

  .messenger-sidebar {
    border-right: 1px solid #d9d9d9;
    width: 318px;
    flex-shrink: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .messenger-content {
    flex-shrink: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
</style>
