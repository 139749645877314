const formatAttribute = ({ code, value }) => {
  return {
    code,
    value: value.map(({ code }) => code)
  }
}

export const formatValuesForRequest = (attributes) => {
  return attributes.map(formatAttribute)
}
