import { API, API_V2, simpleBuildQuery } from "@/utils/HttpUtils"
import i18n from "@/i18n"
import { getLocalizedHeaders, moderationPrefix, transformQuery } from "./servicesUtils.js"
import notifyResponseError from "@/utils/notifyResponseError.js"

export const formatCategoriesTree = (categories) => {
  return categories.map((category) => {
    const { name, uuid, children } = category

    return {
      title: name,
      value: uuid,
      children: children.length > 0 ? formatCategoriesTree(children) : [],
      selectable: !children.length
    }
  })
}

export const notifyCreateProductError = (error) => {
  if (error?.response?.status === 409) {
    notifyResponseError({ error, message: i18n.t("productAlreadyExistWithThisName") })
  } else {
    notifyResponseError({ error, message: i18n.t("creationProductFail") })
  }
}

export const getCategoriesTree = async () => {
  return API.get("mp-admin/categories/tree")
}

export const getTranslatedText = async (text, from, to) => {
  // Потрібен роут для Google translate
  return ""
}

export const fetchProducts = ({ queryParams, requestParams, signal }) => {
  const query = transformQuery(queryParams, requestParams?.changeRouterQuery).replace(
    "ordering",
    "order_by"
  )
  return API_V2.get(
    `${moderationPrefix}/products/${query}`,
    getLocalizedHeaders(requestParams?.language?.value, signal)
  )
}

export const fetchProductTypes = async ({ queryParams, requestParams, signal }) => {
  return API_V2.get(
    `${moderationPrefix}/product-types/${transformQuery(
      queryParams,
      requestParams?.changeRouterQuery
    )}`,

    getLocalizedHeaders(requestParams?.language?.value, signal)
  )
}

export const fetchProductBrands = ({ queryParams, requestParams, signal }) => {
  const query = transformQuery(queryParams).replace("ordering", "order_by")

  return API_V2.get(
    `${moderationPrefix}/brands/${query}`,
    getLocalizedHeaders(requestParams?.language?.value, signal)
  )
}

export const fetchProductRu = ({ code, signal }) => {
  return API_V2.get(`${moderationPrefix}/products/${code}/`, getLocalizedHeaders("ru", signal))
}

export const fetchProductLocalized = ({ code, language = "uk", signal }) => {
  return API_V2.get(`${moderationPrefix}/products/${code}/`, getLocalizedHeaders(language, signal))
}

export const createProduct = (data) => {
  return API_V2.post(`${moderationPrefix}/products/`, data, getLocalizedHeaders("ru"))
}

export const updateProductRu = ({ code, product }) => {
  return API_V2.put(`${moderationPrefix}/products/${code}/`, product, getLocalizedHeaders("ru"))
}

export const updateProductLocalized = ({ code, product, language = "uk" }) => {
  return API_V2.put(`${moderationPrefix}/products/${code}/`, product, getLocalizedHeaders(language))
}

export const exportProduct = (productCode) => {
  return API_V2.post(`${moderationPrefix}/products/export/?code=${productCode}`)
}

export const duplicateProduct = (productCode) => {
  return API_V2.post(`${moderationPrefix}/products/${productCode}/duplicate/`)
}

export const checkExportTask = (params) => {
  return API_V2.get(`/proxy/pm/check_tasks${simpleBuildQuery(params)}`, getLocalizedHeaders("ru"))
}

export const fetchAttributesByType = ({ code, queryParams }) => {
  return API_V2.get(
    `${moderationPrefix}/product-types/${code}/attributes/${simpleBuildQuery(queryParams)}`
  )
}

export const approveProductTrustStatus = ({ code }) => {
  return API_V2.put(
    `${moderationPrefix}/products/${code}/`,
    { trust: "TRUSTED" },
    getLocalizedHeaders("ru")
  )
}

export const changeProductQualityMark = ({ code, qualityMark }) => {
  return API_V2.put(
    `${moderationPrefix}/products/${code}/`,
    { quality_mark: qualityMark },
    getLocalizedHeaders("ru")
  )
}
