import { render, staticRenderFns } from "./ImportAttributeValues.vue?vue&type=template&id=22e497a8&scoped=true"
import script from "./ImportAttributeValues.vue?vue&type=script&setup=true&lang=js"
export * from "./ImportAttributeValues.vue?vue&type=script&setup=true&lang=js"
import style0 from "./ImportAttributeValues.vue?vue&type=style&index=0&id=22e497a8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22e497a8",
  null
  
)

export default component.exports