import i18n from "@/i18n"
import { computed } from "vue"

export default () => {
  const trustOptions = computed(() => [
    {
      key: "TRUSTED",
      label: i18n.t("moderationTrustStatus.TRUSTED"),
      color: "#52c41a"
    },
    {
      key: "UNTRUSTED",
      label: i18n.t("moderationTrustStatus.UNTRUSTED"),
      color: "#faad14"
    }
  ])

  const originOptions = computed(() => [
    {
      key: "SELLER_MANUAL",
      label: i18n.t("originOptions.SELLER_MANUAL")
    },
    {
      key: "SYNTHETIC_DEFAULT",
      label: i18n.t("originOptions.SYNTHETIC_DEFAULT")
    }
  ])

  return {
    trustOptions,
    originOptions
  }
}
