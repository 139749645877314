<template>
  <a-form-model
    ref="formRef"
    :model="formModel"
    :rules="formRules"
    class="add-value-form"
    @submit.prevent="onSubmit"
  >
    <template v-if="props.attributeType === 'NUMERIC'">
      <a-form-model-item
        :label="$t('value')"
        prop="value_numeric"
      >
        <a-input-number
          v-model="formModel.value_numeric"
          style="width: 100%"
        />
      </a-form-model-item>

      <a-form-model-item
        :label="$t('unit')"
        prop="unit"
      >
        <InfiniteScrollSelect
          :value="formModel.unit"
          :customRequest="fetchUnits"
          labelKey="name"
          valueKey="oid"
          :placeholder="$t('search')"
          :defaultOptions="props.defaultUnits"
          @change="onUnitChange"
        />
      </a-form-model-item>
    </template>

    <template v-else>
      <a-form-model-item
        :label="`${$t('value')} (RU)`"
        prop="value.ru"
      >
        <a-input v-model="formModel.value.ru" />
      </a-form-model-item>

      <a-form-model-item
        :label="`${$t('value')} (UA)`"
        prop="value.uk"
      >
        <a-input v-model="formModel.value.uk" />
      </a-form-model-item>

      <a-form-model-item
        :label="`${$t('value')} (EN)`"
        prop="value.en"
      >
        <a-input v-model="formModel.value.en" />
      </a-form-model-item>
    </template>

    <div class="drawer-footer">
      <a-button @click="onClose"> {{ $t("close") }} </a-button>
      <a-button
        type="primary"
        html-type="submit"
        :loading="isSubmiting"
      >
        {{ $t("add") }}
      </a-button>
    </div>
  </a-form-model>
</template>

<script setup>
import { computed, onMounted, reactive, ref, shallowRef } from "vue"
import { notification } from "ant-design-vue"
import i18n from "@/i18n"

import {
  createNewLocalisedAttributeValue,
  createValueForAttribute
} from "../../../services/attributeValuesService.js"
import notifyResponseError from "@/utils/notifyResponseError.js"
import { fetchUnits } from "../../../services/unitsService.js"
import InfiniteScrollSelect from "@/ant-components/InfiniteScrollSelect/InfiniteScrollSelect.vue"

const props = defineProps({
  attributeCode: {
    type: String,
    required: true
  },
  attributeType: {
    type: String,
    default: "STRING"
  },
  attributeUnit: {
    type: Object,
    default: () => ({})
  },
  defaultUnits: {
    type: Array,
    default: () => []
  },
  defaultValue: {
    type: String,
    default: ""
  }
})

const emit = defineEmits(["submit", "close"])

const formRef = ref()
const formModel = reactive({
  value: {
    ru: "",
    uk: "",
    en: ""
  },
  value_numeric: null,
  unit: props.attributeUnit?.oid || undefined
})
const formRules = computed(() => ({
  value_numeric: [{ required: true, message: i18n.t("emptyError") }],
  value: {
    uk: [
      { required: true, message: i18n.t("emptyError") },
      { max: 256, message: i18n.t("maxLengthError", { length: 256 }) }
    ],
    ru: [
      { required: true, message: i18n.t("emptyError") },
      { max: 256, message: i18n.t("maxLengthError", { length: 256 }) }
    ],
    en: [
      { required: true, message: i18n.t("emptyError") },
      { max: 256, message: i18n.t("maxLengthError", { length: 256 }) }
    ]
  },
  unit: props.attributeUnit?.oid ? [{ required: true, message: i18n.t("emptyError") }] : []
}))

const isSubmiting = shallowRef(false)

const onSubmit = async () => {
  try {
    await formRef.value.validate()
  } catch {
    return
  }

  try {
    isSubmiting.value = true

    const payload = { attribute: props.attributeCode }

    if (props.attributeType === "NUMERIC") {
      payload.value = formModel.value_numeric
      payload.unit = formModel.unit
      const { data } = await createValueForAttribute({ payload })
      emit("submit", data)
    } else {
      payload.value = formModel.value
      const response = await createNewLocalisedAttributeValue(payload)
      emit("submit", response)
    }
    notification.success({ message: i18n.t("created") })
    onClose()
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    isSubmiting.value = false
  }
}

const onUnitChange = (value) => {
  formModel.unit = value
}

const onClose = () => {
  emit("close")
}

onMounted(() => {
  if (props.defaultValue && props.attributeType === "NUMERIC") {
    formModel.value_numeric = +props.defaultValue
  } else if (props.defaultValue) {
    formModel.value.ru = props.defaultValue
  }
})
</script>

<style lang="scss" scoped>
.add-value-form {
  padding-bottom: 50px;
}

.success-status {
  color: $green-color;
}

.error-status {
  color: $red-color;
}
</style>
