export default {
  message: "Повідомлення",
  editMessage: "Редагувати повідомлення",
  noImages: "Немає зображень",
  sendFromBuffer: "Відправка зображень з буферу",
  sellerBilling: "Налаштування білінгу",
  sellerSettings: "Налаштування продавця",
  openBillingError: "Не вдалося відкрити білінг продавця",
  lastOption: "Останній варіант",
  noAvaliableChats: "Немає доступних чатів",
  selectChatFromList: "Виберіть чат зі списку",
  deleteMessageForAll: "Видалити це повідомлення для всіх",
  messageDeleted: "Повідомлення видалено",
  hint: "Shift + Enter для переносу рядка",
  contactSupport: "Написати в підтримку",
  messagesModeration: "Модерація повідомлень",
  firstUser: "Ім'я / id користувача 1",
  secondUser: "Ім'я / id користувача 2",
  isTyping: "набирає повідомлення",
  maxMessageLength: "Максимальна довжина повідомлення",
  formatError: "Невалідний формат файла. Дозволені формати",
  sizeError: "Розмір файлу не може перевищувати",
  quantityError: "Можна додати не більше 6 файлів",
  maximum: "Максимум",
  chatsNotSelected: "Чат не вибраний",
  chatOpenedFromProduct: "Чат відкритий зі сторінки товару",
  startDialogWithCustomer: "Почати діалог з продавцем"
}
