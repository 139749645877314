<template>
  <div
    class="messages-display messages"
    :class="{ 'messages-display--empty-state': !messages.length }"
    ref="messagesListRef"
  >
    <ul>
      <a-spin
        v-if="topMessagesLoader"
        class="messages__top-loader"
      />
      <li
        class="message"
        v-for="message in messages"
        :key="message.date"
      >
        <!-- **************** SYSTEM MESSAGE **************** -->
        <template v-if="message.type && message.type === 'PAGE_SOURCE'">
          <div class="message__product-info-wrap">
            <div class="message__hr"></div>
            <div class="message__product-info">
              <div class="product-info__details">
                <div class="product-info__code">
                  {{ $t("chatOpenedFromProduct") }}:
                  <a
                    v-if="hasCorrectOfferCode(parseJSONBody(message))"
                    :href="offerLink(parseJSONBody(message))"
                    target="_blank"
                  >
                    {{ parseJSONBody(message).code }}
                  </a>
                  <a
                    v-else
                    :href="productLink(message)"
                    target="_blank"
                  >
                    {{ parseJSONBody(message).p_code }}
                  </a>
                </div>
                <div class="product-info__product-name">{{ parseJSONBody(message).name }}</div>
              </div>
              <div class="product-info__image">
                <img
                  :src="getImgUrl(parseJSONBody(message).image, '100x100')"
                  @click="openGallery([parseJSONBody(message).image])"
                />
              </div>
            </div>
          </div>
        </template>
        <!-- **************** SIMPLE MESSAGE **************** -->
        <template
          v-else-if="
            message.type &&
            (message.type === 'SIMPLE' ||
              message.type === 'WITH_ATTACHMENTS' ||
              message.type === 'NOTIFICATION')
          "
        >
          <MessageItem
            :message="message"
            :isSelf="checkSelf(message)"
            :userAvatarAbsolutePath="getAvaSrc(message.author.logo, message.author.gender)"
            :username="message.author.first_name || message.author.username"
            :createdAt="getDynamicDateFormat(message.created_at)"
            :mediaContent="getMediaContentArray(message.body)"
            :isRemoved="message.is_removed || false"
            :isEdited="message.is_edited || false"
            :messageBody="message.body || ''"
            :chatUuid="currentChatId"
            :moderatorSearchComponent="true"
            @showEditHistory="$emit('showEditHistory', $event)"
            @loadImage="(img) => forceDownload(img, 'newImage')"
            @loadFile="(file) => forceDownload(file, 'newFile')"
            @edit="$emit('edit', $event)"
          />
        </template>
      </li>
      <a-spin
        v-if="bottomMessagesLoader && !topMessagesLoader && messages.length === 0"
        class="messages__bottom-loader"
      />
    </ul>
    <template
      v-if="!currentChatId"
      class="chat__loader"
    >
      {{ $t("selectChatFromList") }}
    </template>
  </div>
</template>

<script setup>
import MessageItem from "./MessageItem.vue"
import { getChatMessages } from "@/utils/messengerXhrService"
import { inject, nextTick, onBeforeUnmount, onMounted, ref, watch } from "vue"
import { IMAGES_CDN, MARKETPLACE } from "@/constants/common"
import notifyResponseError from "@/utils/notifyResponseError"
import { getDynamicDateFormat } from "@/utils/getFormatedDateTime"

const AVATAR_FEMALE = "@/assets/images/user/avatar-female.svg"
const AVATAR_MALE = "@/assets/images/user/avatar-male.svg"

const props = defineProps({
  currentChatId: {
    type: String,
    default: "",
    required: false
  }
})

const galleryRef = inject("galleryRef")

const messagesListRef = ref()

const scrollHeightBeforeGetPagination = ref(null)
const olderMessagesUnsubscribe = ref(null)
const messages = ref([])
const bottomMessagesLoader = ref(false)
const topMessagesLoader = ref(false)
const totalMessages = ref()

const checkSelf = (message) => {
  const lastMessageIndex = messages.value?.length - 1
  const lastMessage = messages.value[lastMessageIndex]

  return message.author.uuid === lastMessage.author.uuid
}

const hasCorrectOfferCode = (productInfo) => {
  const offerCode = productInfo.code
  const productCode = productInfo.p_code
  /**
   * Previously, there was a bug in which the product code
   * was written in the offer code field
   */
  const offerCodeDoesNotMatchProductCode = offerCode !== productCode

  return offerCode && offerCodeDoesNotMatchProductCode
}

const getMediaContentArray = (str) => {
  if (str === null) return []

  const pCodeTest = /\b(p|P)0[a-zA-Z0-9]{6}\b/gi
  const linkTest =
    /((http|https):\/\/)?(www.)?([a-z0-9-]+\.)+[a-z]{2,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/g
  const links = str.match(linkTest)
  const pCodes = str.match(pCodeTest)
  let results = []

  if (!pCodes && !links) return results

  if (links) {
    let lastIndex = 0

    links.forEach((link) => {
      const linkIndex = str.indexOf(link, lastIndex)

      results.push({
        type: "link",
        startIndex: linkIndex,
        endIndex: linkIndex + link.length,
        result: link
      })

      lastIndex = linkIndex + link.length
    })
  }

  if (pCodes) {
    let lastIndex = 0

    pCodes.forEach((link) => {
      const linkIndex = str.slice(lastIndex).match(new RegExp(`\\b${link}\\b`, "i")).index

      results.push({
        type: "pCode",
        startIndex: linkIndex + lastIndex,
        endIndex: linkIndex + lastIndex + link.length,
        result: link
      })

      lastIndex = linkIndex + link.length
    })
  }

  results.sort((a, b) => a.startIndex - b.startIndex)

  let skipNextCodes = 0

  results = results.filter((result) => {
    if (result.type === "link") {
      const matchAll = result.result.match(pCodeTest)

      skipNextCodes = matchAll ? matchAll.length : 0
    }

    if (result.type === "pCode" && skipNextCodes) {
      skipNextCodes -= 1

      return false
    }

    return true
  })

  const mediaContent = []

  results.forEach((item, i, arr) => {
    // Adding string before first media content
    if (i === 0 && item.startIndex !== 0) {
      mediaContent.push({
        type: "string",
        body: str.slice(0, item.startIndex)
      })
    }

    // Adding string between previous media content
    if (i !== 0 && arr[i - 1].endIndex !== item.startIndex) {
      mediaContent.push({
        type: "string",
        body: str.slice(arr[i - 1].endIndex, item.startIndex)
      })
    }

    // Adding media content
    mediaContent.push({
      type: item.type,
      body: item.result
    })

    // Adding string after last media content
    if (arr.length - 1 === i && item.endIndex !== str.length) {
      mediaContent.push({
        type: "string",
        body: str.slice(item.endIndex, str.length)
      })
    }
  })

  return mediaContent
}

const resetData = () => {
  scrollHeightBeforeGetPagination.value = null
  olderMessagesUnsubscribe.value = null
  messages.value = []
  totalMessages.value = undefined
}

const scrollToBottom = () => {
  nextTick(() => {
    const element = messagesListRef.value

    if (element) {
      element.scrollTop = element.scrollHeight - element.clientHeight
    }
  })
}

const openGallery = (galleryOptions) => {
  galleryRef.value.handleEvent(galleryOptions)
}
const setPreviousScrollAfterGetOldMessages = () => {
  const scrollPosition = messagesListRef.value.scrollHeight - scrollHeightBeforeGetPagination.value

  messagesListRef.value.scrollTop = scrollPosition
  scrollHeightBeforeGetPagination.value = scrollPosition
}
const scrollHandler = () => {
  const { scrollTop, clientHeight, scrollHeight } = messagesListRef.value

  const isScrolledToTop = scrollTop === 0
  const hasScroll = scrollHeight !== clientHeight

  if (isScrolledToTop && hasScroll) {
    getMessages()
  }
}

const getMessages = async () => {
  if (totalMessages.value && messages.value.length >= totalMessages.value) return

  try {
    if (messages.value.length) {
      topMessagesLoader.value = true
    } else {
      bottomMessagesLoader.value = true
    }

    const { data } = await getChatMessages({
      chatUuid: props.currentChatId,
      limit: 15,
      offset: messages.value.length
    })

    totalMessages.value = data.count

    setMessages({
      data: {
        body: {
          chat_uuid: props.currentChatId,
          results: data.results,
          offset: messages.value.length
        }
      },
      id: props.currentChatId
    })
  } catch (error) {
    notifyResponseError({ error })
    resetData()
  } finally {
    topMessagesLoader.value = false
    bottomMessagesLoader.value = false
  }
}

const setMessages = ({ data, id }) => {
  if (!data.body.results.length) return

  scrollHeightBeforeGetPagination.value =
    messagesListRef.value.scrollHeight - messagesListRef.value.scrollTop

  const newMessages = data.body.results.map((item) => {
    const files = []
    const images = []
    if (item.attachments.length) {
      item.attachments.forEach((item) => {
        if (item.type === "image") images.push(item)
        else files.push(item)
      })
    }

    return {
      ...item,
      date: item.created_at,
      self: false,
      content: item.body,
      files,
      images
    }
  })

  if (messages.value.length) {
    messages.value.unshift(...newMessages.reverse())
  } else {
    messages.value = newMessages.reverse()
  }

  if (messages.value.length === newMessages.length) {
    nextTick(scrollToBottom)
  } else {
    nextTick(setPreviousScrollAfterGetOldMessages)
  }
}

const parseJSONBody = (message) => {
  return JSON.parse(message.body)
}

const productLink = (message) => {
  return `${MARKETPLACE}/product/${parseJSONBody(message).slug}/${parseJSONBody(message).p_code}`
}

const offerLink = (productInfo) => {
  return `${MARKETPLACE}/product/${productInfo.slug}/${productInfo.p_code}/${productInfo.code}`
}

const forceDownload = (url, fileName) => {
  const xhr = new XMLHttpRequest()
  xhr.open("GET", url, true)
  xhr.responseType = "blob"
  xhr.onload = (response) => {
    const urlCreator = window.URL || window.webkitURL
    const imageUrl = urlCreator.createObjectURL(response)
    const tag = document.createElement("a")
    tag.href = imageUrl
    tag.download = fileName
    document.body.appendChild(tag)
    tag.click()
    document.body.removeChild(tag)
  }
  xhr.send()
}

const getImagePath = (name, size = "full") => {
  return `${name.slice(0, 16).split("").join("/")}/${size}/${name}`
}

const getImgUrl = (image, size = "full") => {
  return `${IMAGES_CDN}/media/assets/images/${getImagePath(image, size)}`
}

const getAvaSrc = (logo, gender, size) => {
  if (logo === "moderator") {
    return require("../assets/logo.png")
  }
  if (!logo || logo === "placeholder") {
    return gender === "F" ? AVATAR_FEMALE : AVATAR_MALE
  }
  return getImgUrl(logo, size)
}

watch(
  () => props.currentChatId,
  () => {
    resetData()
    if (!props.currentChatId) return
    getMessages()
  }
)

onMounted(() => {
  if (messages.value?.length > 0) {
    scrollToBottom()
  }

  messagesListRef.value.addEventListener("scroll", scrollHandler)

  if (props.currentChatId) {
    resetData()
    getMessages()
  }
})

onBeforeUnmount(() => {
  messagesListRef.value.removeEventListener("scroll", scrollHandler)
})
</script>

<style lang="scss" scoped>
pre {
  white-space: pre-wrap;
  font-size: 14px;
  font-style: normal;
  display: inline;
  font-family: "Roboto";
}

.messages__bottom-loader,
.messages__top-loader {
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>
