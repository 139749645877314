const ProductCatalog = () => import("./ProductCatalog") //lazy load
// children
import ProductCatalogUnits from "./components/ProductCatalogUnits/ProductCatalogUnits"
import ProductCatalogBrands from "./components/ProductCatalogBrands/ProductCatalogBrands"
import ProductCatalogTypes from "./components/ProductCatalogTypes/ProductCatalogTypes"
import i18n from "@/i18n"

const applicationCode = "PRODCAT1"
export default {
  path: "/product-catalog",
  component: ProductCatalog,
  redirect: "/product-catalog/products",
  meta: {
    permissionType: "application",
    permissionCode: applicationCode,
    code: "PRODCAT1",
    title: "Product Catalog"
  },
  children: [
    {
      path: "units",
      component: ProductCatalogUnits,
      meta: {
        permissionType: "component",
        permissionCode: `${applicationCode}/PRODCAT5`,
        code: "PRODCAT5",
        title: i18n.t("units")
      }
    },
    {
      path: "brands",
      component: ProductCatalogBrands,
      meta: {
        permissionType: "component",
        permissionCode: `${applicationCode}/PRODCAT3`,
        code: "PRODCAT3",
        title: i18n.t("brands")
      }
    },
    {
      path: "types",
      component: ProductCatalogTypes,
      meta: {
        permissionType: "component",
        permissionCode: `${applicationCode}/PRODCAT4`,
        code: "PRODCAT4",
        title: i18n.t("types")
      }
    }
  ]
}
