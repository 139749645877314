<template>
  <div class="locales">
    <template v-if="statuses.length">
      <div
        v-for="(item, index) in statuses"
        :key="`${mpcCode}-${index}`"
        class="locale"
        :class="{
          'locale--not-verified': item.value === 'NOT_VERIFIED',
          'locale--verified': item.value === 'VERIFIED'
        }"
        @click="handleEditLocale(item.code)"
      >
        {{ item.code }}
      </div>
    </template>

    <template v-if="!props.locales.length">
      <div
        class="locale locale--not-added"
        @click="handleEditLocale('en')"
      >
        en
      </div>
    </template>
  </div>
</template>

<script setup>
import { computed } from "vue"
import { notification } from "ant-design-vue"
import i18n from "@/i18n"

const props = defineProps({
  locales: {
    type: Array,
    default: () => []
  },
  productStatus: {
    type: String,
    default: ""
  },
  mpcCode: {
    type: String,
    required: true
  }
})
const emit = defineEmits(["onClick"])

const statuses = computed(() => {
  if (!props.locales.length) return []

  const localStatuses = []

  for (let i = 0; i < props.locales.length; i++) {
    const code = Object.keys(props.locales[i])[0]
    const value = Object.values(props.locales[i])[0]

    localStatuses.push({ code, value })
  }

  return localStatuses
})

const handleEditLocale = (locale) => {
  if (props.productStatus === "TRANSFERRED" || props.productStatus === "VERIFIED") {
    emit("onClick", locale)
  } else {
    notification.error({
      message: `(${props.mpcCode}) ${i18n.t("editingProductLocalization")}`,
      description: i18n.t("editingProductLocalizationDescription")
    })
  }
}
</script>

<style lang="scss" scoped>
.locales {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.locale {
  cursor: pointer;

  &:hover {
    opacity: 0.6;
  }

  &--not-added {
    color: #848484;
  }

  &--not-verified {
    color: $danger-color;
  }

  &--verified {
    color: $success-color;
  }
}
</style>
