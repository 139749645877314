import { API, API_MESSENGER } from "./HttpUtils"

export const globalChatSearch = (payload) => {
  return API_MESSENGER.get("admin/chats/", {
    params: { sender: "moderator", ...payload }
  })
}

export const getChatMessages = ({ chatUuid, limit, offset }) => {
  return API_MESSENGER.get(`admin/messages/`, {
    params: { sender: "moderator", limit, offset, chat: chatUuid }
  })
}

export const getMessageHistory = ({ messageUuid, limit, offset }) => {
  return API_MESSENGER.get(`admin/messages/${messageUuid}/history/`, {
    params: { sender: "moderator", limit, offset }
  })
}
