<template>
  <a-form-model
    ref="formRef"
    :model="form"
    :rules="formRules"
    class="create-post-office-form"
    @submit.prevent="onSubmit"
  >
    <a-form-model-item
      :label="$t('deliveryService')"
      prop="service"
    >
      <InfiniteScrollSelect
        :value="form.service"
        :showSearch="false"
        :customRequest="fetchDeliveryServices"
        valueKey="uuid"
        onFocusFetch
        @change="onServiceChange"
      >
        <template #option="{ option }">
          {{ option.name?.uk || option.name?.ru || option.name?.en || "" }}
        </template>
      </InfiniteScrollSelect>
    </a-form-model-item>

    <div class="create-post-office-form__row">
      <a-form-model-item
        :label="`${$t('publicName')} (UA)`"
        prop="public_title.uk"
      >
        <a-input v-model="form.public_title.uk" />
      </a-form-model-item>

      <a-form-model-item
        :label="`${$t('publicName')} (RU)`"
        prop="public_title.ru"
      >
        <a-input v-model="form.public_title.ru" />
      </a-form-model-item>

      <a-form-model-item
        :label="`${$t('publicName')} (EN)`"
        prop="public_title.en"
      >
        <a-input v-model="form.public_title.en" />
      </a-form-model-item>
    </div>

    <div class="create-post-office-form__row">
      <a-form-model-item
        :label="$t('city')"
        prop="city"
      >
        <InfiniteScrollSelect
          :value="form.city"
          :customRequest="fetchLocations"
          :customRequestParams="citySearchParams"
          searchKey="locality"
          valueKey="uuid"
          onFocusFetch
          @change="onCityChange"
        >
          <template #option="{ option }">
            {{ option?.name?.uk || option?.name?.ru || option?.name?.en || "" }}
          </template>
        </InfiniteScrollSelect>
      </a-form-model-item>

      <a-form-model-item
        :label="$t('number')"
        prop="number"
      >
        <a-input-number
          v-model="form.number"
          :precision="0"
        />
      </a-form-model-item>
    </div>

    <div class="create-post-office-form__row">
      <a-form-model-item
        :label="$t('address')"
        prop="address"
      >
        <a-input v-model="form.address" />
      </a-form-model-item>

      <a-form-model-item
        :label="$t('digitalAddress')"
        prop="digital_address"
      >
        <a-input v-model="form.digital_address" />
      </a-form-model-item>
    </div>

    <div class="create-post-office-form__row create-post-office-form__row--half">
      <a-form-model-item
        :label="$t('type')"
        prop="type"
      >
        <a-select
          v-model="form.type"
          :options="props.postTypeOptions"
        />
      </a-form-model-item>
    </div>

    <a-divider
      style="margin: 0"
      orientation="left"
    >
      {{ $t("additionally") }}
    </a-divider>

    <div class="create-post-office-form__row create-post-office-form__row--free">
      <a-form-model-item
        :label="$t('weightTo')"
        prop="max_weight"
      >
        <a-input-number
          v-model="form.meta.max_weight"
          :precision="0"
        />
      </a-form-model-item>

      <a-form-model-item
        :label="$t('maxCost')"
        prop="max_declared_cost"
      >
        <a-input-number
          v-model="form.meta.max_declared_cost"
          style="width: 100%"
          :precision="0"
        />
      </a-form-model-item>
    </div>

    <div class="create-post-office-form__row">
      <a-form-model-item :colon="false">
        <template #label>
          {{ $t("sizeLimitsByReceive") }}
          <a-switch
            style="margin-left: 16px"
            @change="onRecieveLimitsChange"
          />
        </template>
        <div
          v-if="form.meta.receive_limits_on_dimensions"
          class="create-post-office-form__row"
        >
          <a-form-model-item prop="meta.receive_limits_on_dimensions.width">
            <a-input-number
              v-model="form.meta.receive_limits_on_dimensions.width"
              :placeholder="$t('width')"
              :precision="0"
              style="width: 100%"
            />
          </a-form-model-item>
          <a-form-model-item prop="meta.receive_limits_on_dimensions.height">
            <a-input-number
              v-model="form.meta.receive_limits_on_dimensions.height"
              :placeholder="$t('height')"
              :precision="0"
              style="width: 100%"
            />
          </a-form-model-item>
          <a-form-model-item prop="meta.receive_limits_on_dimensions.length">
            <a-input-number
              v-model="form.meta.receive_limits_on_dimensions.length"
              :placeholder="$t('length')"
              :precision="0"
              style="width: 100%"
            />
          </a-form-model-item>
        </div>
      </a-form-model-item>

      <a-form-model-item :colon="false">
        <template #label>
          {{ $t("sizeLimitsBySend") }}
          <a-switch
            style="margin-left: 16px"
            @change="onSendLimitsChange"
          />
        </template>
        <div
          v-if="form.meta.send_limits_on_dimensions"
          class="create-post-office-form__row"
        >
          <a-form-model-item prop="meta.send_limits_on_dimensions.width">
            <a-input-number
              v-model="form.meta.send_limits_on_dimensions.width"
              :placeholder="$t('width')"
              :precision="0"
              style="width: 100%"
            />
          </a-form-model-item>

          <a-form-model-item prop="meta.send_limits_on_dimensions.height">
            <a-input-number
              v-model="form.meta.send_limits_on_dimensions.height"
              :placeholder="$t('height')"
              :precision="0"
              style="width: 100%"
            />
          </a-form-model-item>

          <a-form-model-item prop="meta.send_limits_on_dimensions.length">
            <a-input-number
              v-model="form.meta.send_limits_on_dimensions.length"
              :placeholder="$t('length')"
              :precision="0"
              style="width: 100%"
            />
          </a-form-model-item>
        </div>
      </a-form-model-item>
    </div>

    <a-divider
      style="margin: 0"
      orientation="left"
    >
      {{ $t("workSchedule") }}
    </a-divider>

    <a-form-model-item>
      <div style="display: flex; flex-wrap: wrap; gap: 16px">
        <a-form-model-item
          v-for="(dayTime, key) of form.schedule"
          :key="key"
          :prop="`schedule.${key}`"
        >
          <div style="width: 50px">{{ $t(`${key}Shorted`) }}:</div>
          <a-input-group compact>
            <a-time-picker
              v-model="form.schedule[key][0]"
              format="HH:mm"
              valueFormat="HH:mm"
              :disabledHours="getDisabledHours(form.schedule[key], 'start')"
            />
            <a-time-picker
              v-model="form.schedule[key][1]"
              format="HH:mm"
              valueFormat="HH:mm"
              :disabledHours="getDisabledHours(form.schedule[key], 'end')"
            />
          </a-input-group>
        </a-form-model-item>
      </div>
    </a-form-model-item>

    <div class="drawer-footer">
      <a-form-model-item style="margin: 0">
        <a-button
          :disabled="props.isCreating"
          @click="onClose"
        >
          {{ i18n.t("cancel") }}
        </a-button>
      </a-form-model-item>
      <a-form-model-item style="margin: 0">
        <a-button
          type="primary"
          htmlType="submit"
          :loading="props.isCreating"
        >
          {{ i18n.t("apply") }}
        </a-button>
      </a-form-model-item>
    </div>
  </a-form-model>
</template>

<script setup>
import { computed, onMounted, reactive, ref, shallowRef } from "vue"
import { message } from "ant-design-vue"
import i18n from "@/i18n"

import { fetchLocations } from "@/modules/MPAdmin/services/locationsService.js"

import notifyResponseError from "@/utils/notifyResponseError.js"
import InfiniteScrollSelect from "@/ant-components/InfiniteScrollSelect/InfiniteScrollSelect.vue"
import { useLocationComposables } from "@/composables/useLocationComposables.js"
import { fetchDeliveryServices } from "@/modules/MPAdmin/services/generalSettingService/deliveryService"

const props = defineProps({
  postTypeOptions: {
    type: Array,
    default: () => []
  },
  isCreating: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits(["submit", "close"])

const { localityTypes } = useLocationComposables()

const formRef = ref()
const form = reactive({
  number: "",
  address: "",
  type: "DEPARTMENT",
  digital_address: "",
  city: undefined,
  service: undefined,
  public_title: {
    uk: "",
    ru: "",
    en: ""
  },
  schedule: {
    monday: ["", ""],
    tuesday: ["", ""],
    wednesday: ["", ""],
    thursday: ["", ""],
    friday: ["", ""],
    saturday: ["", ""],
    sunday: ["", ""]
  },
  meta: {
    receive_limits_on_dimensions: null, // { width: 123, height: 123, length: 123 }
    send_limits_on_dimensions: null, // { width: 123, height: 123, length: 123 }
    max_weight: null,
    max_declared_cost: ""
  }
})
const requiredRule = computed(() => [{ required: true, message: i18n.t("emptyError") }])
const formRules = computed(() => {
  const rules = {
    public_title: {
      uk: requiredRule.value,
      ru: requiredRule.value,
      en: requiredRule.value
    },
    digital_address: requiredRule.value,
    address: requiredRule.value,
    service: requiredRule.value,
    type: requiredRule.value,
    number: requiredRule.value,
    city: requiredRule.value,
    meta: {}
  }

  if (form.meta.send_limits_on_dimensions) {
    rules.meta.send_limits_on_dimensions = {
      width: requiredRule.value,
      height: requiredRule.value,
      length: requiredRule.value
    }
  }

  if (form.meta.receive_limits_on_dimensions) {
    rules.meta.receive_limits_on_dimensions = {
      width: requiredRule.value,
      height: requiredRule.value,
      length: requiredRule.value
    }
  }

  return rules
})

const getDisabledHours = (value, position) => () => {
  if (!value[0] && !value[1]) return []

  const startHour = +value[0]?.split(":")[0]
  const endHour = +value[1]?.split(":")[0]

  if (position === "start") {
    if (!value[1]) return []
    return Array.from({ length: 24 - endHour - 1 }, (_, i) => endHour + i)
  } else {
    if (!value[0]) return []
    return Array.from({ length: startHour ? startHour + 1 : 0 }, (_, i) => i)
  }
}

const onSendLimitsChange = (value) => {
  form.meta.send_limits_on_dimensions = value ? { width: null, height: null, length: null } : null
}

const onRecieveLimitsChange = (value) => {
  form.meta.receive_limits_on_dimensions = value
    ? { width: null, height: null, length: null }
    : null
}

// CITIES
const citySearchParams = computed(() => ({
  type: localityTypes.value.slice(2).map(({ value }) => value)
}))

const onCityChange = (value) => {
  form.city = value
}

// SERVICES
const onServiceChange = (value) => {
  form.service = value
}

const onSubmit = async () => {
  try {
    await formRef.value.validate()
  } catch {
    return message.error(i18n.t("fillFields"))
  }

  const { schedule, ...payload } = form
  payload.schedule = {}

  for (const key of Object.keys(schedule)) {
    const [start, end] = schedule[key]

    if (start && end) {
      payload.schedule[key] = `${start}-${end}`
    } else {
      payload.schedule[key] = ""
    }
  }
  payload.meta.max_declared_cost = form.meta.max_declared_cost.toString()

  emit("submit", payload)
}
const onClose = () => {
  emit("close")
}
</script>

<style lang="scss" scoped>
.create-post-office-form {
  padding-bottom: 50px;

  &__row {
    display: flex;
    gap: 16px;

    & > * {
      flex: 1;
    }

    &--half {
      width: calc(50% - 8px);
    }

    &--free {
      width: fit-content;
    }
  }
}
</style>
