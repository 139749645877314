<template>
  <a-select
    v-model="selectedUnit"
    showArrow
    :loading="loading"
    :showSearch="showSearch"
    :filterOption="false"
    :placeholder="$t('unit')"
    :allowClear="allowClear"
    :class="{ 'unit-select--full': props.fullWidth }"
    @search="onUnitSearch"
    @change="onChange"
  >
    <template
      v-if="isFetchingUnits"
      #notFoundContent
    >
      <a-spin style="width: 100%" />
    </template>
    <a-select-option
      v-for="option in unitOptions"
      :key="option[props.usedField]"
      :value="option[props.usedField]"
    >
      {{ option.name }}
    </a-select-option>
  </a-select>
</template>

<script setup>
import { ref, watch } from "vue"
import { fetchUnits } from "../../services/unitsService"
import notifyResponseError from "@/utils/notifyResponseError"
import getCurrentLocale from "@/utils/getCurrentLocale"

const props = defineProps({
  value: {
    type: String,
    default: undefined
  },
  allowClear: {
    type: Boolean,
    default: false
  },
  showSearch: {
    type: Boolean,
    default: false
  },
  loading: {
    type: Boolean,
    default: false
  },
  defaultOptions: {
    type: Array,
    default: () => []
  },
  usedField: {
    type: "oid" | "code",
    default: "oid"
  },
  fullWidth: {
    type: Boolean,
    default: false
  },
  returnObject: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits(["update:value", "change"])

const selectedUnit = ref(props.value)
const isFetchingUnits = ref(false)
const unitSearchDebounce = ref()
const unitOptions = ref(props.defaultOptions)

watch(
  () => props.value,
  (newVal) => {
    selectedUnit.value = newVal
  }
)

watch(
  () => selectedUnit.value,
  (newVal) => {
    emit("update:value", newVal)
  }
)

watch(
  () => props.defaultOptions.length,
  () => {
    unitOptions.value = props.defaultOptions
  }
)

const onUnitSearch = async (value) => {
  if (value === "") {
    unitOptions.value = props.defaultOptions
    return
  }
  if (unitSearchDebounce.value) {
    clearTimeout(unitSearchDebounce.value)
  }

  unitSearchDebounce.value = setTimeout(() => handleSearchUnits(value), 500)
}

const handleSearchUnits = async (value) => {
  try {
    isFetchingUnits.value = true

    const { data } = await fetchUnits({
      queryParams: getSearchQueryParams("name", value),
      requestParams: {
        language: { value: getCurrentLocale() }
      }
    })

    unitOptions.value = data.results
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    unitSearchDebounce.value = undefined
    isFetchingUnits.value = false
  }
}

const getSearchQueryParams = (field, value) => ({
  limit: 10,
  offset: 0,
  [field]: value
})

const onChange = (value) => {
  if (props.returnObject) {
    const unit = unitOptions.value.find((unit) => unit[props.usedField] === value)
    return emit("change", unit)
  }

  emit("change", selectedUnit.value)
}
</script>

<style lang="scss" scoped>
.unit-select--full {
  width: 100%;
}
</style>
