<template>
  <div>
    <a-upload-dragger
      :beforeUpload="onChooseFile"
      accept="text/csv"
      :fileList="currentFiles"
      :remove="() => (currentFiles = [])"
      :disabled="isFetching"
    >
      <div class="upload-field">
        <a-icon
          type="inbox"
          class="upload-field__icon"
        />

        {{ $t("chooseOrDragForUpload") }}
      </div>
    </a-upload-dragger>

    <div class="drawer-footer">
      <a-button
        :loading="isFetching"
        @click="$emit('close')"
      >
        {{ $t("cancel") }}
      </a-button>

      <a-button
        type="primary"
        :loading="isFetching"
        :disabled="!currentFiles.length"
        @click="onSubmit"
      >
        {{ $t("apply") }}
      </a-button>
    </div>
  </div>
</template>

<script setup>
import { ref, shallowRef } from "vue"
import { notification } from "ant-design-vue"
import i18n from "@/i18n"

import notifyResponseError from "@/utils/notifyResponseError.js"
import { importAttributeValuesFile } from "../../../services/attributeValuesService.js"

const emit = defineEmits(["close"])

const currentFiles = ref([])
const isFetching = shallowRef(false)

const onChooseFile = (file) => {
  if (file.name.split(".")[1] !== "csv") {
    notification.error({ message: `${i18n.t("formatError")}: .csv` })
    return false
  }

  currentFiles.value = [file]
  return false
}

const onSubmit = async () => {
  try {
    isFetching.value = true

    await importAttributeValuesFile(currentFiles.value[0])

    notification.info({ message: i18n.t("importStarted") })
    emit("close")
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    isFetching.value = false
  }
}
</script>

<style lang="scss" scoped>
.upload-field {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  gap: 20px;

  font-size: 16px;

  &__icon {
    color: $primary-color;
    font-size: 36px;
  }
}
</style>
