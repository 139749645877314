<template>
  <a-drawer
    :visible="visible"
    :title="`${$t('editingProductLocalization')} ${productCode} (${locale})`"
    width="90%"
    @close="onClose"
  >
    <EditProductLocalizationCommonTab
      ref="productCommonRef"
      :mpcCode="productCode"
      :product="product"
      :product-localized="productLocalized"
      :fetching="productFetching"
      :locale="locale"
      @formChanged="productChange"
    />

    <div class="drawer-footer">
      <a-button @click="onClose">
        {{ $t("close") }}
      </a-button>

      <a-button
        type="primary"
        :loading="saveProcessing"
        @click="onSubmit"
      >
        {{ $t("save") }}
      </a-button>
    </div>
  </a-drawer>
</template>

<script setup>
import { computed, provide, ref, watch } from "vue"
import { Modal, notification } from "ant-design-vue"
import i18n from "@/i18n"

import EditProductLocalizationCommonTab from "./_components/EditProductLocalizationCommonTab.vue"

import {
  fetchProductRu,
  fetchProductLocalized,
  updateProductLocalized
} from "@/modules/Moderation/services/moderationProductsService.js"
import notifyResponseError from "@/utils/notifyResponseError.js"

const props = defineProps({
  visible: {
    type: Boolean,
    required: true
  },
  mpcCode: {
    type: String,
    default: ""
  },
  locale: {
    type: String,
    default: ""
  }
})
const emit = defineEmits(["onClose"])

provide(
  "languageForDataFetch",
  computed(() => props.locale)
)

const productCommonRef = ref()

const productFetching = ref(false)
const saveProcessing = ref(false)
const product = ref({})
const productLocalized = ref({})
const hasChangedProductData = ref(false)
const productCode = computed(() => props.mpcCode)

const productChange = () => {
  hasChangedProductData.value = true
}
const clearData = () => {
  hasChangedProductData.value = false
  product.value = {}
  productLocalized.value = {}
  emit("onClose")
}
const onClose = () => {
  if (!hasChangedProductData.value) {
    clearData()
    return
  }

  Modal.confirm({
    title: i18n.t("localizationEditingWarning"),
    content: i18n.t("cancelEditingWarning"),
    okText: i18n.t("close"),
    cancelText: i18n.t("cancel"),
    onOk: clearData()
  })
}

const onSubmit = async () => {
  try {
    saveProcessing.value = true

    const product = await productCommonRef.value?.getLocalizedFormValues()
    const params = {
      code: productCode.value,
      product,
      language: props.locale
    }

    await updateProductLocalized(params)

    hasChangedProductData.value = false
    notification.success({ message: i18n.t("updated") })
  } catch (error) {
    notifyResponseError({ error, message: i18n.t("notUpdated") })
  } finally {
    saveProcessing.value = false
  }
}

const getProduct = async () => {
  if (!props.mpcCode) return
  const params = {
    code: props.mpcCode,
    language: props.locale
  }

  productFetching.value = true

  try {
    const [resultsDefaultLocale, resultsEditableLocale] = await Promise.all([
      await fetchProductRu(params),
      await fetchProductLocalized(params)
    ])

    product.value = resultsDefaultLocale.data
    productLocalized.value = resultsEditableLocale.data

    if (!resultsEditableLocale?.data?.attributes?.length) return
  } catch (error) {
    notifyResponseError({ error, message: i18n.t("notUpdated") })
  } finally {
    productFetching.value = false
  }
}

watch(() => props.mpcCode, getProduct)
</script>
