/* eslint-disable */
// @ts-ignore
import Vue from "vue"
import "ant-design-vue/dist/antd.css"
import Antd from "ant-design-vue"
import _ from "lodash"
import Vuelidate from "vuelidate"
import PortalVue from "portal-vue"
import VueDragscroll from "vue-dragscroll"
import i18n from "./i18n"

import App from "./App.vue"
import { store } from "./store/store"
import router from "./router"
import "./filters"
import SynLib from "./Library"
import Grid from "./components/Gallery/drag"
import "@/assets/css/messenger/index.scss"

Vue.config.productionTip = false
Vue.prototype.$_ = _

Vue.use(SynLib)
Vue.use(Antd)
Vue.use(Vuelidate)
Vue.use(Grid)
Vue.use(VueDragscroll)
Vue.use(Vuelidate)
Vue.use(PortalVue)

new Vue({
  el: "#app",
  store,
  i18n,
  router: router,
  render: (h) => h(App)
})
