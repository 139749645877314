<template>
  <a-form-model
    ref="formRef"
    :model="form"
    :rules="formRules"
    @submit.prevent="onSubmit"
  >
    <a-form-model-item
      :label="$t('chooseType')"
      prop="product_type"
    >
      <InfiniteScrollSelect
        :value="form.product_type"
        :customRequest="fetchTypes"
        labelKey="full_name"
        valueKey="code"
        :placeholder="$t('search')"
        @change="onTypeChange"
      />
    </a-form-model-item>

    <a-form-model-item
      prop="is_main"
      style="margin: 0"
      :title="$t('status')"
    >
      <a-checkbox v-model="form.is_main">
        {{ $t("attributeIsMain") }}
      </a-checkbox>
    </a-form-model-item>

    <a-form-model-item
      prop="is_required"
      style="margin: 0"
    >
      <a-checkbox v-model="form.is_required">
        {{ $t("attributeIsRequired") }}
      </a-checkbox>
    </a-form-model-item>

    <div class="drawer-footer">
      <a-button @click="onClose">
        {{ $t("cancel") }}
      </a-button>

      <a-button
        type="primary"
        :loading="isFetching"
        htmlType="submit"
      >
        {{ $t("toTether") }}
      </a-button>
    </div>
  </a-form-model>
</template>

<script setup>
import { reactive, ref, computed } from "vue"
import { fetchTypes } from "../../../services/typesService.js"
import { attachTypeToAttribute } from "../../../services/attributesService.js"
import notifyResponseError from "@/utils/notifyResponseError.js"
import i18n from "@/i18n"
import { notification } from "ant-design-vue"
import InfiniteScrollSelect from "@/ant-components/InfiniteScrollSelect/InfiniteScrollSelect.vue"

const props = defineProps({
  attribute: {
    type: Object,
    required: true
  }
})
const emit = defineEmits(["submit", "close"])

const formRef = ref()
const form = reactive({
  product_type: undefined,
  is_main: false,
  is_required: false
})
const formRules = computed(() => ({
  product_type: [{ required: true, message: i18n.t("emptyError") }]
}))

const dataSource = ref([])
const isFetching = ref(false)

const onTypeChange = (value) => {
  form.product_type = value
}

const onSubmit = async () => {
  try {
    isFetching.value = true

    const payload = { ...form, order: 0 }

    await attachTypeToAttribute({ attributeCode: props.attribute.code, payload })
    notification.success({ message: i18n.t("success") })

    emit("submit")
    onClose()
  } catch (error) {
    notifyResponseError({ error })
  } finally {
    isFetching.value = false
  }
}

const onClose = () => {
  emit("close")
}
</script>
