// @ts-ignore
import Vue from "vue"
import Vuex from "vuex"

import ProductCatalog from "../modules/ProductCatalog/store"
import Auth from "../modules/Auth/store"
import MPAdmin from "../modules/MPAdmin/store"
import Messenger from "../modules/Messenger/store"
import spvMessenger from "@/modules/MPAdmin/pages/MessengerModerator/messengerStore"

Vue.use(Vuex)

export const store = new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  getters: {},
  modules: {
    ProductCatalog, //name prefix: 'PC_'
    Auth,
    MPAdmin,
    Messenger,
    spvMessenger
  }
})
