import { transformQuery, moderationPrefix } from "./servicesUtils"
import { API_V2, API_MODERATION } from "@/utils/HttpUtils"
import buildMagicSearch from "@/modules/ProductCatalog/store/utils/buildMagicSearch"
import getCurrentLocale from "@/utils/getCurrentLocale"

const getLocalizedAxiosParams = (language) => ({
  headers: {
    "Accept-language": language
  }
})

export const fetchTypes = ({ queryParams, requestParams, signal }) => {
  return API_V2.get(
    `${moderationPrefix}/product-types/${transformQuery(
      queryParams,
      requestParams?.changeRouterQuery
    )}`,
    {
      headers: {
        "Accept-language": requestParams?.language?.value || getCurrentLocale()
      },
      signal
    }
  )
}

export const createType = ({ data, language }) => {
  return API_V2.post(`${moderationPrefix}/product-types/`, data, {
    headers: {
      "Accept-language": language
    }
  })
}

export const updateType = ({ code, payload, language }) => {
  return API_V2.put(`${moderationPrefix}/product-types/${code}/`, payload, {
    headers: {
      "Accept-language": language
    }
  })
}

export const fetchAttributesByType = ({ code, language }) => {
  return API_V2.get(
    `${moderationPrefix}/product-types/${code}/attributes/`,
    getLocalizedAxiosParams(language)
  )
}

export const searchATL = async (typeCode) => {
  const params = {
    type_code: typeCode
  }

  return API_MODERATION.get("/product-catalog/moderation/search/get_attributes_by_type", { params })
}

export const updateATL = (sendData) => {
  return API_MODERATION.put("/product-catalog/moderation/dictionary/link", sendData)
}

export const updateATLOrder = (sendData) => {
  return API_MODERATION.put("/product-catalog/moderation/dictionary/link/order-update", sendData)
}

export const removeAttributeFromType = (params) => {
  return API_MODERATION.delete("/product-catalog/moderation/dictionary/link", { params })
}

export const fetchTypeForCopy = (value) => {
  let searchData = buildMagicSearch({
    resource: "PTD",
    field: "full_name",
    value,
    sort: null,
    limit: 10,
    offset: 0
  })

  return API_MODERATION.post(
    "/product-catalog/moderation/search/magic",
    searchData,
    getLocalizedAxiosParams("ru")
  )
}

export const tetherAttribute = (atl) => {
  return API_MODERATION.post("/product-catalog/moderation/dictionary/link", atl)
}
