import { render, staticRenderFns } from "./EditProductCommonTab.vue?vue&type=template&id=fbdc33ca&scoped=true"
import script from "./EditProductCommonTab.vue?vue&type=script&setup=true&lang=js"
export * from "./EditProductCommonTab.vue?vue&type=script&setup=true&lang=js"
import style0 from "./EditProductCommonTab.vue?vue&type=style&index=0&id=fbdc33ca&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fbdc33ca",
  null
  
)

export default component.exports