import { transformQuery, moderationPrefix, getLocalizedHeaders } from "./servicesUtils.js"
import { API_V2 } from "@/utils/HttpUtils"

export const fetchUnits = ({ queryParams, requestParams, signal }) => {
  return API_V2.get(
    `${moderationPrefix}/attribute-units/${transformQuery(
      queryParams,
      requestParams?.changeRouterQuery
    )}`,
    getLocalizedHeaders(requestParams?.language?.value, signal)
  )
}
