<template>
  <div
    class="message__main"
    :class="{ message__self: isSelf }"
  >
    <a-avatar
      class="message__avatar"
      :loadError="() => false"
      :size="50"
      :src="userAvatarAbsolutePath"
    />

    <div class="message__align-center">
      <div
        class="message__info"
        :class="{ 'message__info--notification': message.type === 'NOTIFICATION' }"
      >
        <div
          class="message__info-header"
          v-if="!isRemoved"
        >
          <p class="message__name">
            {{ username }}
          </p>
          <p class="message__time">
            {{ createdAt }}
          </p>
        </div>
        <div class="message__content">
          <div v-if="isRemoved">
            <a-icon
              type="delete"
              style="font-size: 14px"
            />
            {{ $t("messageDeleted") }}
          </div>
          <div v-else-if="messageBody">
            <div class="content__text">
              <pre v-if="!mediaContent.length">{{ messageBody }}</pre>

              <!-- CONTENT WITH MEDIA CONTENT -->
              <template v-else>
                <span
                  v-for="(record, index) in mediaContent"
                  :key="index"
                >
                  <!-- PRODUCT CODE -->
                  <a
                    v-if="record.type === 'pCode'"
                    :href="getProductUrlByProductCode(record.body)"
                    target="_blank"
                    >{{ record.body }}</a
                  >

                  <!-- LINKS -->
                  <a
                    v-else-if="record.type === 'link'"
                    :href="record.body"
                    target="_blank"
                    >{{ record.body }}</a
                  >

                  <!-- SIMPLE TEXT -->
                  <pre v-else>{{ record.body }}</pre>
                </span>
              </template>
            </div>
          </div>
          <template v-if="message.images && message.images.length">
            <div class="content__images">
              <span
                v-for="(img, index) in message.images"
                :key="index"
                class="content__image-container"
              >
                <img
                  :src="img.file"
                  alt="img"
                  class="content__image"
                  @load="onLoad"
                  @click="
                    openGallery({
                      images: message.images.map((img) => img.file),
                      startIndex: index
                    })
                  "
                />
                <span
                  class="content__images-download-wrap"
                  @click="$emit('loadImage', img.file)"
                >
                  <a-icon
                    class="content__images-download"
                    type="download"
                  />
                </span>
              </span>
            </div>
          </template>
          <template v-if="message.files && message.files.length">
            <p
              v-for="(file, index) in message.files"
              :key="`${file}-${index}`"
              class="content__file"
            >
              <a @click.prevent="$emit('loadFile', file.file)">
                <a-avatar
                  class="file__icon"
                  :size="32"
                  icon="file"
                />
                <div>
                  <div class="file__name">{{ file.name_original }}</div>
                  <span>{{ $t("download") }}</span>
                </div>
              </a>
            </p>
          </template>
          <span
            v-if="!isRemoved"
            class="message__status"
          >
            <span
              style="padding-left: 8px"
              v-if="!isRemoved && message.status === 'read'"
            >
              <a-icon
                class="message__status-read"
                style="margin-right: -6px"
                type="check"
              />
              <a-icon
                class="message__status-read"
                type="check"
              />
            </span>
            <a-icon
              v-else-if="!isRemoved && message.status === 'sent'"
              class="message__status-sent"
              type="check"
            />
            <span v-if="isEdited || isRemoved">
              <template v-if="isEdited">{{ $t("edited") }}</template>
              <span
                class="message__edit-history"
                v-if="isEdited"
              >
                (<a
                  @click.prevent="
                    $emit('showEditHistory', {
                      messageUuid: message.uuid,
                      originalMessageBody: messageBody
                    })
                  "
                  >{{ $t("changeHistory").toLowerCase() }}</a
                >)
              </span>
              <template v-if="isRemoved">{{ $t("messageDeleted") }}</template>
            </span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { MARKETPLACE } from "@/constants/common"
import { inject } from "vue"

const props = defineProps({
  message: {
    type: Object,
    required: true
  },
  isSelf: {
    type: Boolean,
    required: true
  },
  isRemoved: {
    type: Boolean,
    required: true
  },
  isEdited: {
    type: Boolean,
    required: true
  },
  userAvatarAbsolutePath: {
    type: String,
    required: true
  },
  username: {
    type: String,
    required: true
  },
  createdAt: {
    type: String,
    required: true
  },
  mediaContent: {
    type: Array,
    required: true
  },
  messageBody: {
    type: String,
    required: true
  }
})

const galleryRef = inject("galleryRef")

const getProductUrlByProductCode = (pCode = "") => {
  return `${MARKETPLACE}/product/slug/${pCode}`
}
const openGallery = (galleryOptions) => {
  galleryRef.value.handleEvent(galleryOptions)
}
const onLoad = (e) => {
  const imageEl = e.target

  if (!imageEl) return

  const { height } = getComputedStyle(imageEl)

  if (parseFloat(height) < 200) {
    imageEl.parentElement.style.height = height
  }
}
</script>

<style lang="scss" scoped>
.message__status {
  flex-direction: row-reverse;
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: $border-color;
  font-size: 10px;
  align-items: center;
}

.message__self .message__status {
  color: #fff;
}

.message__main {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  &.message__self {
    flex-direction: row-reverse;

    .message__align-center {
      flex-direction: row-reverse;
    }
  }
}
</style>
