import { computed } from "vue"
import i18n from "@/i18n"

export default ({ sortedInfo } = {}) => {
  const attributesListColumns = computed(() => [
    {
      key: "timestamp",
      dataIndex: "timestamp",
      title: i18n.t("date"),
      scopedSlots: {
        customRender: "dateRenderer"
      },
      width: 150,
      sorter: true,
      sortOrder: sortedInfo?.value?.columnKey === "timestamp" && sortedInfo?.value?.order,
      fixed: "left"
    },
    {
      key: "code",
      dataIndex: "code",
      title: i18n.t("attributeCode"),
      width: 120,
      fixed: "left"
    },
    {
      key: "name",
      dataIndex: "name",
      title: i18n.t("workName"),
      width: 500
    },
    {
      key: "public_name",
      dataIndex: "public_name",
      scopedSlots: {
        title: "publicNameTitleRenderer"
      },
      width: 500
    },
    {
      key: "value_type",
      dataIndex: "value_type",
      title: i18n.t("type"),
      scopedSlots: {
        customRender: "valueTypeRenderer"
      },
      width: 140
    },
    {
      key: "default_unit",
      dataIndex: "default_unit.name",
      title: i18n.t("baseUnit"),
      align: "center",
      width: 180
    },
    {
      key: "trust",
      dataIndex: "trust",
      title: i18n.t("status"),
      scopedSlots: {
        customRender: "trustRenderer"
      },
      width: 160,
      fixed: "right"
    },
    {
      key: "actions",
      scopedSlots: {
        customRender: "actionsRenderer"
      },
      width: 64,
      fixed: "right"
    }
  ])

  return attributesListColumns
}
