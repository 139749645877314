export default {
  message: "Message",
  editMessage: "Edit message",
  noImages: "No images",
  sendFromBuffer: "Sending images from buffer",
  sellerBilling: "Seller billing",
  sellerSettings: "Seller settings",
  openBillingError: "Can't open seller billing",
  lastOption: "Last option",
  noAvaliableChats: "No avaliable chats",
  selectChatFromList: "Select chat from list",
  deleteMessageForAll: "Delete this message for everyone",
  messageDeleted: "Message deleted",
  messagesModeration: "Messages moderation",
  firstUser: "First user name / id",
  secondUser: "Second user name / id",
  isTyping: "is typing",
  hint: "Shift + Enter for new line",
  contactSupport: "Contact support",
  maxMessageLength: "Maximum message length",
  formatError: "Invalid file format. Allowed formats",
  sizeError: "File size have to be less than",
  quantityError: "Maximum 6 files allowed",
  maximum: "Maximum",
  chatsNotSelected: "Chats not selected",
  chatOpenedFromProduct: "Chat is open from the product page",
  startDialogWithCustomer: "Start dialog with customer"
}
